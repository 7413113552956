import styles from "../css/RefundPolicy.css";
const PageNotFound = () =>
<section className="inner-pages" id="innerpages">
    <div className="container">
    <div className="row">
        <div className="col col-md12 text-center" style={{height:"300px"}}>
        <h1 className="fs-1 fw-bold"> 404 </h1> 
        <p className="fs-3"> 
            Oops! The page you're 
            looking for is not here. 
        </p> 
        <a href="https://www.celebrationssquare.com/"> 
            Go Back to Home 
        </a>         </div>
    </div>

    </div>
    
</section>
export default PageNotFound;