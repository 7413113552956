export const  HOST = 'https://celebrationssquare.com/';
// export const  HOST = 'http://localhost:8080/';
export const  GET_THEATERS_LIST = HOST + 'searchSlots';
export const  GET_THEATERA_BY_DATE = 'https://dummyjson.com/products/1';
export const  GET_META_DATA_ADDONS = HOST + 'fetchMeta';
// export const  BOOK_EVENT = HOST + 'bookEvent';
export const GET_ALL_EVENTS = HOST + 'findEvents';
export const GET_ALL_BOOK_VIA_CALL_DATA = HOST + 'findBookViaCallData';
export const GET_ALL_UPCOMING_EVENTS = HOST + 'findUpcomingEvents';
export const ADMIN_ADD_UPDATE_TIMESLOT = HOST + 'addUpdateTimeslot';
export const ADMIN_ADD_UPDATE_EVENTTYPE = HOST + 'addUpdateEventType';
export const ADMIN_ADD_UPDATE_ADDONS = HOST + 'addUpdateAddons';
export const ADMIN_ADD_UPDATE_THEATRE = HOST + 'addUpdateTheatre';
export const ADMIN_LOGIN_VALIDATE = HOST + 'validateAdmin';
export const BOOK_VIA_CALL = HOST + 'bookViaCall';
export const GET_EVENT_BY_BOOK_REF_ID = HOST + 'findEventByBookRefId';
export const  BOOK_NOW_AND_PAY_LATER = HOST + 'bookNowPayLater';
export const  UPDATE_EVENT_DETAILS = HOST + 'updateEvent';
export const  UPDATE_BOOK_BY_CALL_DETAILS = HOST + 'updateBookByCallDetails';
export const  CANCEL_EVENT = HOST + 'cancelEvent';
export const  BOOK_NOW_AND_PAY = HOST + 'bookNowAndPay';
export const  UPDATE_PD = HOST + 'updatePd';
export const  ENABLE_PG = 'N';