import React , { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Background from "../images/footer_bg.jpg";
import { useNavigate } from "react-router-dom";

var sectionStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: "url(" + { Background } + ")",
};
const Home = () => {
  let navigate = useNavigate();
const [count, setCount] = useState(3);
let imageNum = 1;
    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
          
           count > 8 ? setCount(1) : setCount(count + 1);
        }, 3000);
 
        //Clearing the interval
        return () => clearInterval(interval);
    }, [count]);
    // console.log(count);
    imageNum = count;
  return (
    <div>
      <section
        className="bsb-hero-1 bsb-overlay bsb-hover-pull"
        style={{height:"550px", backgroundImage: `url(${require("../images/Theatre_images/"+imageNum+".jpg")})`,backgroundPosition:"center",backgroundSize:'cover',transition:"background 300ms ease-in 200ms" }}
      >
        <div style={{backgroundColor:"rgba(0,0,0,0.6)", height:"100%"}} className="p-md-5 p-3">
          <div className="row">
            <div
              className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-white p-3"
             
            >
              <h2
                className="display-3 fw-bold mb-3 text-white lh-1"
              >
                Unleash the magic of special moments.
              </h2>
              <p className="fs-md-5 my-md-5 mb-5 text-light lh-2" style={{letterSpacing:"1px"}}>
                Welcome to "Celebrations Square" your premier destination for an
                extraordinary private theatre and event celebration experience
                in Hyderabad! Contact us today to reserve your date and let the
                cinematic celebrations begin!
              </p>
              <div className="d-grid gap-3 d-sm-flex">
                <button
                  type="button"
                  className="btn bsb-btn-xl btn-danger"
                  onClick={() => navigate("/bookNow")}
                >
                  Book Now
                </button>
                <a href="https://www.instagram.com/celebrations_square/" target="_blank" rel="noreferrer"
                  className="btn bsb-btn-xl btn-outline-light text-light"
                >
                 <i className="bi bi-instagram"></i>&nbsp;
                  View Samples
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="home-carousel" >
    <Carousel style={{borderBottom:"35px solid #a50706"}}><Carousel.Item interval={5000} className='carousel-1'>
        <img
            className="d-block w-100"
            src={require('../images/theater1.jpg')}
            alt="celebration square theatre 1" height="560px"
        />
        <Carousel.Caption>
            <h1>Unleash the magic of
                special movements</h1>
            <h2>Welcome to Celebration Square,Book your private theatre experience at
                Celebration Square and make your birthdays and anniversaries truly
                extraordinary.Contactus today to reserve your date and let the cinematic
                celebrations begin!</h2>
        </Carousel.Caption>
    </Carousel.Item> <Carousel.Item interval={1500}>
            <img
                className="d-block w-100"
                src={require('../images/theater2.jpg')}
                alt="celebration squrae theatre 2" height="560px"
            />
            <Carousel.Caption>
                <h3>Unleash the magic of special moments...</h3>
            </Carousel.Caption>
        </Carousel.Item></Carousel>
       
    

</section> */}
      <section style={{ backgroundColor: "rgb(0,0,0,0.8)", padding: "20px" }}>
        <div className="container text-white" style={{ textAlign: "center" }}>
          <h3 className="display-6 fw-bold lh-sm">
            <span style={{ color: "#FCF1CA" }}>
              Bring your private theatre experience at
            </span>{" "}
            <span style={{ color: "#D2AC2B" }}>Celebrations Square</span>
          </h3>
          <p className="fs-5 lh-sm">
            Make your birthdays and anniversaries truly extraordinary.{" "}
            <br className="d-none d-sm-block"></br>Contact us today to reserve
            your date and let the cinematic celebrations begin!
          </p>
        </div>
      </section>

      <section className="container">
        <div className="my-4" id="features">
          <div className="row" >
            <div className="col col-lg-4 mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/Icon1.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Elevate your Special Occasions
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Transform your birthdays and anniversaries into unforgettable
                  moments at Celebrations Square. Immerse yourself in the magic
                  of our private theatres, creating memories that last a
                  lifetime.
                </p>
              </div>
            </div>
            <div className="col col-lg-4  mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/icon2.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Exclusive Cinema Experience
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Experience the glamour of a private cinema with your loved
                  ones. Our state-of-the-art theatres provide the perfect
                  setting for an intimate celebration, featuring top-notch
                  audiovisuals and comfortable seating.
                </p>
              </div>
            </div>
            <div className="col col-lg-4  mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/icon3.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Tailored<br></br> Packages
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Choose from our specially curated packages to suit your
                  celebration style. Whether it's a blockbuster birthday bash or
                  a romantic anniversary screening, we have the perfect package
                  for you.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-4  mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/icon4.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Gourmet<br></br> Treat
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Indulge in our gourmet popcorn, delectable snacks, and
                  refreshing beverages as you enjoy the movie. Elevate your
                  celebration with a touch of culinary excellence.
                </p>
              </div>
            </div>
            <div className="col col-md-4  mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/icon5.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Personalized<br></br> Touch
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Add a personal touch to your event with customizable options.
                  From themed decorations to personalized screenings, we ensure
                  every detail reflects your unique celebration.
                </p>
              </div>
            </div>
            <div className="col col-md-4  mb-2">
              <div
                className="card p-4"
                style={{
                  position: "relative",
                  minHeight: "270px",
                  border: "1px solid rgb(249, 149, 37)",
                }}
              >
                <img
                  src={require("../images/icon6.png")}
                  style={{ position: "absolute", top: "2%", width: "75px" }}
                ></img>
                <h3
                  style={{ paddingLeft: "90px", color: "#a50706" }}
                  className="lh-1 fs-4 fw-bold"
                >
                  Capture the Moment
                </h3>
                <p style={{ paddingLeft: "90px" }} className="lh-sm py-2 ">
                  Document your special day with our professional photography
                  services. We'll capture the joy and laughter, creating a
                  visual narrative of your celebration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          style={{
            background: "rgb(255,255,255)",
            padding: "20px 20px",
            textAlign: "center",
          }}
        >
          <h2 className=" fw-bold mb-3 display-6 lh-1">
            <span style={{ color: "rgb(43, 81, 210)" }}>Celebrate togetherness</span>{" "}
            with{" "}
            <span style={{ color: "#D2AC2B" }}>big-screen entertainment</span>{" "}
            <br></br>along with your loved ones
          </h2>
          <p className=" fs-5 lh-sm">
            Dive into the richness of Dolby Atmos compliant sound systems and
            enhanced 4k Video Private Theaters in Hyderabad
          </p>

          <div className="container">
            <div className="card-group">
              <div className="card">
                <img
                  src={require("../images/Theatre_images/1.jpg")}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Birthday Party</h5>
                  <p className="card-text  lh-sm">
                    Celebrate your birthday in style with our private theatre
                    experience. Enjoy watching your favorite movies or shows.
                  </p>
                 
                
                </div>
                <div className="card-footer"> <a onClick={() => navigate("/bookNow")} className="btn btn-danger">
                    Book Now
                  </a></div>
              </div>
              <div className="card">
                <img
                  src={require("../images/Theatre_images/4.jpg")}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Anniversary</h5>
                  <p className="card-text  lh-sm">
                    Mark your milestone anniversary with a romantic and intimate
                    private theatre celebration.
                  </p>
                  
                </div>
                <div className="card-footer"><a onClick={() => navigate("/bookNow")} className="btn btn-danger">
                    Book Now
                  </a></div>
              </div>
              <div className="card">
                <img
                  src={require("../images/Theatre_images/8.jpg")}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Party & Events</h5>
                  <p className="card-text  lh-sm">
                    Whether it's a reunion, farewell, or any other social
                    gathering, our private theatres provide a unique and
                    exclusive venue
                  </p>{" "}
                 
                </div>
                <div className="card-footer"> <a onClick={() => navigate("/bookNow")} className="btn btn-danger">
                    Book Now
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
