import styles from "../css/RefundPolicy.css";
const Privacy = () =>
<section className="inner-pages" id="innerpages">
    <div className="container">
    <div className="row">
        <div className="col col-md12">
        {/* Effective Date: [Insert Date] */}
<br></br>
CelebrationsSquare ("we", "us", or "our") is committed to protecting the privacy of our users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the choices you have regarding your information. By accessing or using our website, you agree to the terms of this Privacy Policy.

<ul style={{listStyleType:"number"}}>
<li>Information We Collect:</li>

<p>Personal Information: When you visit our website or make a booking for our private theater celebrations, we may collect personal information such as your name, email address, phone number, and billing information.
</p>
<p>Usage Information: We may collect non-personal information about your interactions with our website, such as the pages visited, the time spent on each page, and the links clicked.
</p>
<li>Use of Information:</li>

We may use the personal information we collect to:
<ul><li>Process and fulfill your bookings.</li>
<li>Communicate with you regarding your bookings or inquiries.</li>
<li>Send you promotional offers and updates about our services.</li>
</ul>
We may use usage information to analyze trends, administer the website, and gather demographic information about our user base.

<li>Sharing of Information:</li>

<ul>
<li>We may share your personal information with third-party service providers who assist us in operating our website and providing our services. These service providers are obligated to keep your information confidential and secure.
</li>
<li>We may also disclose your information if required by law or to protect our rights, property, or safety, or that of others.</li>
</ul>
<li>Cookies:</li>

We may use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small text files stored on your device that help us remember your preferences and settings.

<li>Data Security:</li>

We take reasonable measures to protect the security of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.
<li> Choices:</li>

You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the emails.
You can also disable cookies in your browser settings, although this may affect your experience on our website.
<li>Children's Privacy:</li>

Our website is not directed to children under the age of 13, and we do not knowingly collect personal information from children.
<li>Changes to this Privacy Policy:</li>

We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the revised policy on this page.
<li>Contact Us:</li>

If you have any questions or concerns about this Privacy Policy, please contact us at [insert contact information].
By using our website, you consent to the collection and use of your information as described in this Privacy Policy.
</ul>
          </div>
    </div>

    </div>
    
</section>
export default Privacy;