import React, {  useContext, useEffect, useState } from 'react';

import SelectedTheater from './SelectTheater';
import TheateDetails from '../components/TheaterDetails';
import axios from 'axios';
import * as Constants  from '../api/constants';
import { Context } from '../App';

const BookNow = () => {
    const [selectedTheater, seselectedTheater] = useState(false);
    const {setTheatersList, setAddonMetaData, context, SetSelectedTime, selectedTheaterByDate} = useContext(Context);

    const fetchTheaterByDate = () => {
        seselectedTheater(true);
    }
    useEffect(()=>{
        let evDate = {date:context?.eventDate};
        if(evDate.date == undefined){
            evDate = new Date().toISOString().split("T")[0];
            evDate = {date:evDate};
            console.log('evDate setting to default : ',evDate);
        }
      axios.all([axios.post(Constants.GET_THEATERS_LIST,evDate),
           axios.get(Constants.GET_META_DATA_ADDONS)])
     .then(axios.spread((firstResponse, secondResponse) => {
        const {details} = firstResponse?.data;
         setTheatersList(details);
         setAddonMetaData(secondResponse?.data);
         if(selectedTheaterByDate?.id) {
            var selectedEvent = details?.filter((val)=>val?.id === selectedTheaterByDate?.id);
            SetSelectedTime(selectedEvent[0]?.timeslots[0])
         }
         else {
            SetSelectedTime(details[0]?.timeslots[0])
         }
     }))
     .catch(error => console.log(error));
    },[context, setAddonMetaData, setTheatersList, SetSelectedTime])
    return (
        <>
        {!selectedTheater ? 
            <SelectedTheater selectedBy={()=>fetchTheaterByDate()}/>
         : <TheateDetails/>}
        </>
    )
}
export default BookNow;