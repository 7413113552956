import React from 'react';
import jsonMetaData from '../json/addon_form.json';
import { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import * as constants  from '../api/constants';

const AdminMetaDataForm = ({ type , data, selectedTab, togglePopup}) => {

    const [formDataObject, setFormDataObject] = useState(data);
    
    const formElements = selectedTab === 'Addons' ? type === 'Add Addon' ? jsonMetaData.addNewAddon.formElements : jsonMetaData.addonsData.formElements : jsonMetaData[type].formElements;

    const changeOptions = (e, name, isCheckbox) => {
        let formData = {...formDataObject};
        formData[name] = isCheckbox ? e.target.checked ? 'Y' : 'N' : e.target.value;
        setFormDataObject(formData);
    }

    const checkAllareFilled = (payloadObj) => {
        let isValidated = true;
        Object.entries(payloadObj).forEach(([key, value]) => {
            if(key !== 'id' && (value === null || value === '')) {
                isValidated = false;
            }
        });
        return isValidated;
    }

    const handleSave = () => {
        let payloadObj = {};
        formElements.forEach((element) => {
            payloadObj[element.name] = formDataObject[element.name] ?? null;
        });
        const isValidated = checkAllareFilled(payloadObj);
        if(!isValidated){
            alert('Please fill all the fields');
            return;
        }
        let apiName = selectedTab === 'Addons' ? 'addUpdateAddon' : 'addUpdateTheatre';
        if(selectedTab === 'Addons'){
            apiName = constants.ADMIN_ADD_UPDATE_ADDONS;
        } else if(type.toLowerCase() === 'theatres'){
            apiName = constants.ADMIN_ADD_UPDATE_THEATRE;
        } else if(type.toLowerCase() === 'eventtypes'){
            apiName = constants.ADMIN_ADD_UPDATE_EVENTTYPE;
        } else if(type.toLowerCase() === 'timeslots'){
            apiName = constants.ADMIN_ADD_UPDATE_TIMESLOT;
        }axios.post(apiName, payloadObj).then((response) => {
            console.log('Saved Successfully', response.data);
            alert('Saved Successfully');
            togglePopup(false);
        }).catch((error) => {
            alert('Error in Saving the data. Please try again later.');
            console.error('Error in Saving the data', error);
        });
        
    }


    const renderFormElement = (element) => {
        if(element.show){
            if(element.type === 'text'){
                return (
                    <Form.Group controlId={element.name}>
                        <Form.Label>{element.label}</Form.Label>
                        <Form.Control type="text" value={formDataObject[element.name]} onChange={(e) => changeOptions(e, element.name)} placeholder={element.placeholder}/>
                    </Form.Group>
                );
            }
            else if(element.type === 'number'){
                return (
                    <Form.Group controlId={element.name}>
                        <Form.Label>{element.label}</Form.Label>
                        <Form.Control type="number" value={formDataObject[element.name]} onChange={(e) => changeOptions(e, element.name)} placeholder={element.placeholder}/>
                    </Form.Group>
                );
            }
            else if(element.type === 'boolean'){
                return (
                    
                    <div key={element.name} className="form-check form-check-inline" style={{paddingTop: '0.5rem'}}>
                        <input className="form-check-input" type="checkbox" name={element.label} id={element.name} checked={formDataObject[element.name] === 'Y' ? true : false} onChange={(e) => changeOptions(e, element.name, true)}/>
                        <label className="form-check-label" htmlFor={element.name}>{element.label}</label>
                    </div>
                            
                );
            }
        }
    }

    return (
        <>
        <Modal.Header closeButton>
            <Modal.Title>Add/Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <div className="admin-meta-data-form">
                    {
                        formElements.map((element) => {
                            return (
                                <div key={element.name} className="form-group">
                                    {renderFormElement(element)}
                                </div>
                            );
                        })
                    }
                </div>
                <div style={{color:'red'}}>* All fields are mandatory</div>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => togglePopup(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => handleSave()}>Save</Button>
        </Modal.Footer>
        </>
    );
};

export default AdminMetaDataForm;
