import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Context } from '../App';

const DatePicker = ({dateStyle, onDateChange}) =>{
    const {context, setContext} = useContext(Context);
    const currentDate = new Date().toISOString().split('T')[0];
    const currentYear = new Date().getFullYear() + 1;
    const onChange = (date) => {
        setContext({"eventDate": date});
        onDateChange()
       }
    return(
        <div className="my-4  justify-content-center d-md-flex dm-sans-csquare" id="checkSlot">
            <div className={dateStyle}>
                <Form.Group controlId="slot" className='position-relative'>
                   <Form.Label className='fs-6 px-1 text-center position-absolute bg-white' style={{top:'-12px', left:'20px',fontWeight:'bold'}}>Check Slot Availability</Form.Label>
                   <Form.Control 
                      type="date" 
                      name="eventDate"
                      placeholder="Select a slot for celebration"
                      value={context.eventDate}
                      defaultValue={currentDate}
                      onChange={(e)=>onChange(e.target.value)} 
                      className='cs-select fs-5 px-3'
                      min={currentDate}
                      max={currentYear+"-12-31"}
                    />
                </Form.Group>
            </div>
            </div>
    )
}

export default DatePicker;