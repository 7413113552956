import styles from "../css/RefundPolicy.css";
import { useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Addons = () =>{
  let userAgent=false;
  function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }
  
  if (isMobile()) {
    console.log("Mobile device detected");
    userAgent = true;
  } else {
    console.log("Desktop device detected");
    userAgent = false;
  }
const [key, setKey] = useState('addons');
const addonsList = {
  "addOns": {
    "decor": [
      {
        "decorName": "hbdLetters",
        "decorDispName": "HBD Letters",
        "decorImage": "HBD"
      },
      {
        "decorName": "ledNumber",
        "decorDispName": "LED Numbers",
        "decorImage": "LED"
      },
      {
        "decorName": "fogEffect",
        "decorDispName": "Fog Effect",
        "decorImage": "FOG_effect"
      },
      {
        "decorName": "partyProps",
        "decorDispName": "Party Props",
        "decorImage": "Party_Props"
      }
    ],
    "cakes": [
      {
        "cakeName": "blackcurrentFB",
        "cakeDispName": "Black Current",
        "cakeImage": "blackcurrentFB"
      },
      {
        "cakeName": "bluebery",
        "cakeDispName": "Blue Berry",
        "cakeImage": "bluebery"
      },
      {
        "cakeName": "Choco-vanilla-FB",
        "cakeDispName": "Choco Vanilla",
        "cakeImage": "Choco-vanilla-FB"
      },
      {
        "cakeName": "Chocolate-heart-cakeFB",
        "cakeDispName": "Chocolate",
        "cakeImage": "Chocolate-heart-cakeFB"
      }
    ],
    "photos": [
      {
        "photoName": "20",
        "photoDispName": "20 pics",
        "photoImage": "PHOTOS"
      },
      {
        "photoName": "50",
        "photoDispName": "50 pics",
        "photoImage": "PHOTOS"
      },
      {
        "photoName": "75",
        "photoDispName": "75 pics",
        "photoImage": "PHOTOS"
      },
      {
        "photoName": "100",
        "photoDispName": "100 pics",
        "photoImage": "PHOTOS"
      },
      {
        "photoName": "unlimited",
        "photoDispName": "unlimited(1 hour)",
        "photoImage": "PHOTOS"
      }
    ]
  }
}
return (
<section className="inner-pages" id="innerpages">
    <div className="container">
        <div>
        <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="m-3"
    >
      <Tab eventKey="addons" title="Add-ons" className="px-4">
      <ul className='list-unstyled mw-100' style={{columnCount:userAgent ? "2":"4",columnGap:"20px"}}>
      {addonsList.addOns.decor.map(decor => (<li className='mt-0 mb-md-4 mb-3 '>
                { <img 

                  src={require('../images/add-ons/'+decor.decorImage+'.svg')} className="rounded-3"
                  alt={decor.decorDispName} loading="lazy" 
                /> }
                <p className="text-center fs-5 fw-bold my-3">{decor.decorDispName}</p>
              </li>))}  
              </ul>    </Tab>
      <Tab eventKey="photography" title="Photography">
      <ul className='list-unstyled mw-100 text-center'>
      {addonsList.addOns.photos.map(photo => (<li className='mt-0 mb-md-4 mb-3 d-inline-block'>
                { <img 

                  src={require('../images/'+photo.photoImage+'.svg')} className="rounded-3"
                  alt={"photography "+photo.photoDispName} loading="lazy" width={"50%"}
                /> }
                <p className="fs-5 fw-bold my-3">{"Photography "+photo.photoDispName}</p>
              </li>))}  
              </ul>  
      </Tab>
      <Tab eventKey="cakes" title="Cakes">
      <ul className='list-unstyled mw-100' style={{columnCount:userAgent ? "2":"4",columnGap:"20px"}}>
      {addonsList.addOns.cakes.map(cake => (<li className='mt-0 mb-md-4 mb-3 '>
                { <img 

                  src={require('../images/cakes-images/'+cake.cakeImage+'.svg')} className="rounded-3"
                  alt={cake.cakeDispName} loading="lazy" 
                /> }
                <p className="text-center fs-5 fw-bold my-3">{cake.cakeDispName}</p>
              </li>))}  
              </ul>   
      </Tab>
    </Tabs>
        </div>
    </div>
</section>
);
};
export default Addons;