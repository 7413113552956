import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import TheatersList from '../components/TheatersList';
import { Context } from '../App';
import DatePicker from '../components/DatePicker';
import styles from '../css/SelectTheater.css';
import {Modal,Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import * as Constants from "../api/constants";
import axios from "axios";
import Toaster from "../components/Toaster";

const SelectTheater = ({ selectedBy }) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [toasterDetails, setToasterDetails] = useState(null);
    const navigate = useNavigate();
    const formRef = useRef(null);

    const [validated, set_Validated] = useState(false);

    const [form_Data, set_Form_Data] = useState({
      name: "",
      eventDate: "",
      noOfGuests: 2,
      preferredTime: "9:00 AM to 12:00 PM",
      mobileNumber: "",
      email: "",
      notes: "",
      referredBy: "",
      location: "",
      eventType: "Birthday",
      locationId: 1
    });

      const handleReset = () => {
        formRef.current.reset();
        set_Validated(false);
      };
      const submitFn = (event) => {
        const form = document.getElementById("viacall_form");
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
           handleBookViaApiClick();
        }
        set_Validated(true);
      };
      const chngFn = (event) => {
        const { name, value } = event.target;
        set_Form_Data({
          ...form_Data,
          [name]: value,
        });
      };
      const handleBookViaApiClick = () => {
        setShowSpinner(true);
        var  modalCloseBtn = document.getElementById("modal-close-btn");
          axios
            .post(Constants.BOOK_VIA_CALL, { ...form_Data })
            .then((response) => {
              let { status, message } = response?.data;
              setShowSpinner(false);
              setToasterDetails({
                variant: "Success",
                message: `${message}`,
              });
              document.getElementById("requestCallBackLabel").scrollIntoView();
              handleReset();
              setTimeout(() => {
                setToasterDetails(null);
                modalCloseBtn.click();
                navigate("/home");
              }, 3000);
            })
            .catch((error) => {
              console.log(error);
              setShowSpinner(false);
              setToasterDetails({
                variant: "Danger",
                message:
                  error?.response?.data?.message ||
                  "Unable to Book the Event via call please try again",
              });
              document.getElementById("requestCallBackLabel").scrollIntoView();
            });
        };
   
    
    const currentDate = new Date().toISOString().split('T')[0];

    const { theatersList, setselectedTheaterByDate, setTotalCost, SetSelectedTime } = useContext(Context);

    const fetchTheaterByDate = (theater) => {
        selectedBy(true);
        setselectedTheaterByDate(theater);
        SetSelectedTime(theater?.timeslots[0])
        setTotalCost(theater?.timeslots[0]?.basePrice)
    }
    const onreset = () => {
        setTotalCost(0);
    }

    return (<section className='bookNow'>
        <div className='d-md-flex justify-content-center  bk_bg  container py-4'>
            <div className='select_theater rounded-4 p-3 text-center'>
                <h1 className='logo-color-2 fw-bold text-center mb-5'>Choose your theatre </h1>

                <>

                    <DatePicker onDateChange={onreset} />
                    {theatersList?.length && <TheatersList TheatersList={theatersList} getTheaterDetails={(theater) => fetchTheaterByDate(theater)} />}
                </>
                <button type="button" className="btn btn-dark flex flex-col text-light fw-bold fs-4" data-bs-toggle="modal" data-bs-target="#requestCallBack"><i className="bi bi-telephone-outbound-fill"></i>&nbsp;&nbsp;Book via call</button>

            </div>

        </div>
        {showSpinner && (
           <div className="d-flex justify justify-content-center align-items-center" style={{position:"fixed",top:"0",left:"0",width:"100%",height:"100%",zIndex:"11000",background:"rgba(0, 0, 0, 0.3)"}}> <Spinner animation="border" role="status" className="d-flex justify-content-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>
          )}

        <div className="modal fade" id="requestCallBack" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title fs-4 logo-color-1" id="requestCallBackLabel">Request Callback</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modal-close-btn"></button>
                    </div>
                    <div className="modal-body">
                    {toasterDetails && <Toaster details={toasterDetails} />}
                        <Form ref={formRef} noValidate
                              validated={validated}
                              onSubmit={submitFn}
                              id="viacall_form">
                            <Form.Group className="mb-3" >
                                <Form.Label>Name *</Form.Label>
                                <Form.Control type="text" name='name' className="form-control" id="callBackName" placeholder="Enter name" value={form_Data.user}
                                    required  onChange={chngFn}
                                    pattern="^[a-zA-Z\s]+$"
                                    maxLength={20}
                                    isInvalid={
                                      validated &&
                                      !/^[a-zA-Z\s]+$/.test(form_Data.user)
                                    }/>
<Form.Control.Feedback type="invalid">
                                  Please enter a valid name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackDate">Select date</label>

                                <Form.Control
                                    type="date"
                                    name="eventDate"
                                    placeholder="Select a slot for celebration"
                                    defaultValue={currentDate}
                                    min={currentDate}
                                    value={form_Data.eventDate}
                                    onChange={chngFn}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackPersons">Number of Person(s)</label>
                                <Form.Select className="form-control" name="noOfGuests" value={form_Data.noOfGuests} id="callBackPersons" onChange={chngFn}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackTime">Event Type</label>
                                <Form.Select className="form-control" name="eventType" value={form_Data.eventType} id="eventType" onChange={chngFn}>
                                    <option value="Birthday">Birthday</option>
                                    <option value="Anniversary">Anniversary</option>
                                    <option value="Bride To be">Bride To be</option>
                                    <option value="Groom To be">Groom To be</option>
                                    <option value="Mom To be">Mom To be</option>
                                    <option value="Farewell">Farewell</option>
                                    <option value="Romantic Date">Romantic Date</option>
                                    <option value="Marriage Proposal">Marriage Proposal</option>
                                    <option value="Love Proposal">Love Proposal</option>
                                    <option value="Baby Shower">Baby Shower</option>
                                    <option value="Congratulations">Congratulations</option>
                                    <option value="Get Together">Get Together</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackTime">Time Preference</label>
                                <Form.Select className="form-control" name="preferredTime" value={form_Data.preferredTime} id="callBackTime" onChange={chngFn}>
                                    <option value="9:00 AM to 12:00 PM">Morning (9:00 AM to 12:00 PM)</option>
                                    <option value="12:30 PM to 3:30 PM">Afternoon (12:30 PM to 3:30 PM)</option>
                                    <option value="4:00 PM to 7:00 PM">Evening (4:00 PM to 7:00 PM)</option>
                                    <option value="7:30 PM to 10:30 PM">Night (7:30 PM to 10:30 PM)</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackContact">WhatsApp Number(10 digit Indian number) *</label>
                                <input type="tel" className="form-control" id="callBackContact" name="mobileNumber"
                                  value={form_Data.phoneNo}
                                  onChange={chngFn}
                                  pattern="^\d{10}$"
                                  placeholder="WhatsApp number (10 digits of Indian Number)*"
                                  required
                                  isInvalid={
                                    validated &&
                                    !/^\d{10}$/.test(form_Data.phoneNo)
                                  } />
 <Form.Control.Feedback type="invalid">
                                  Please enter a valid 10-digit phone number.
                                </Form.Control.Feedback>                            </Form.Group>
                                <Form.Group className="mb-3" >
                                <Form.Label>Email *</Form.Label>
                                <Form.Control type="text" name='email' className="form-control" id="callBackName" placeholder="Email" value={form_Data.email}
                                    required  onChange={chngFn}
                                    />
<Form.Control.Feedback type="invalid">

</Form.Control.Feedback>
</Form.Group>
<Form.Group className="mb-3" >
                                <Form.Label>Reference</Form.Label>
                                <Form.Control type="text" name='referredBy' className="form-control" id="referredBy" placeholder="Reference" value={form_Data.reference}
                                     onChange={chngFn}
                                    />
</Form.Group>
<Form.Group className="mb-3" >
                                <Form.Label>Your Location</Form.Label>
                                <Form.Control type="text" name='location' className="form-control" id="location" placeholder="Your Location" value={form_Data.location}
                                     onChange={chngFn}
                                    />
</Form.Group>
                            <Form.Group className="mb-3" >
                                <label htmlFor="callBackNotes">Special notes - 200 chars</label>
                                <textarea className="form-control" id="callBackNotes" rows="3" maxLength="240" name="notes" onChange={chngFn}></textarea>
                            </Form.Group>
                           
                        </Form>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <Button type="submit" className="btn btn-primary btn-color-1" id="bookViaCallSubmitBtn" onClick={submitFn}>Submit</Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
};

export default SelectTheater;