import React, { useEffect, useState, useContext, useRef } from 'react';
import { Card, Form, Button, Modal,Spinner } from 'react-bootstrap';
import axios from 'axios';
import * as Constants  from '../api/constants';
import EditEventDetails from '../components/EditEventDetails';
import { Context } from '../App';
import { useNavigate  } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CsvExportModule } from "ag-grid-community";
import { ModuleRegistry } from "ag-grid-community";
import Select from 'react-select';

ModuleRegistry.registerModules([
  CsvExportModule,
]);

const AdminDashboard = () => {
    const gridRef = useRef();
/*
    ID
NAME
EVENT_DATE
NO_OF_GUESTS
PREFERRED_TIME
MOBILE_NUMBER
NOTES
STATUS
PAYMENT_STATUS
CREATED_DATE
MODIFIED_DATE
CREATED_BY
UPDATED_BY
referred_by
location
location_id

*/

    const tableMeta = [
        {headerName: 'S No', field: 'sNo', width: 75},
        {headerName: 'Name', field: 'name', width: 150},
        {headerName: 'Requested date', field: 'createdDate', width: 150},
        {headerName: 'Event Date', field: 'eventDateToDisplay', width: 120},
        {headerName: 'Event Type', field: 'eventType', width: 120},
        {headerName: 'Guests', field: 'noOfGuests', width: 80},
        {headerName: 'Preferred Time', field: 'preferredTime', width: 170},
        {headerName: 'MOBILE_NUMBER', field: 'mobileNumber', width: 150},
        {headerName: 'Status', field: 'status', width: 180},
        {headerName: 'Final price', field: 'status', width: 180},
        {headerName: 'Referrence', field: 'referredBy', width: 150},
        {headerName: 'Customer Location', field: 'location', width: 150},
        {headerName: 'Notes', field: 'notes', width: 180},
        
        
    ];
    const nextDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()+30);

    const [eventDate, setEventDate] = useState(new Date().toISOString().split('T')[0]);
    const [eventToDate, setEventToDate] = useState(nextDate.toISOString().split('T')[0]);
    const [custEventDate, setCustEventDate] = useState(nextDate.toISOString().split('T')[0]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [finalPaymentToPay, setFinalPaymentToPay] = useState(0);
    const [selectedCard, setSelectedCard] = useState({});
    const [data, setData] = useState([]);
    const {context, setContext} = useContext(Context);
    const [selectedTheatre, setSelectedTheatre] = useState('');
    const navigate = useNavigate();
    const [availableTimeSlotData, setAvailableTimeSlotData] = useState([]);
    const [fullMetaData, setFullMetaData] = useState(null);
    const [timeSlotObj, setTimeSlotObj] = useState({});
    const [initialSelectedSlotId, setInitialSelectedSlotId] = useState(null);
    const [seatsPrice, setSeatsPrice] = useState(0);
    const [foodOtherPrice, setFoodOtherPrice] = useState(0);
    const [advancePaidTillNow, setAdvancePaidTillNow] = useState(0);
    const [addsOnsPrice, setAddsOnsPrice] = useState(0);

    if(!context.isAdminLoggedIn){
        navigate('/admin');
    }

    const calculateSeatsPrice = () => {
        if(timeSlotObj){
            const selectedSeats = selectedCard.totalSeats;
            const defaultSeats = timeSlotObj.defaultCapacity;
            const extraPerPerson = timeSlotObj.extraPerPerson;
            const basePrice = timeSlotObj.basePrice;
            const advTillnow = selectedCard.totalAmountPaid;
            // setAdvancePaidTillNow(advTillnow);
            if(selectedSeats <= defaultSeats){
                setSeatsPrice(basePrice);
            }else{
                const extraSeats = selectedSeats - defaultSeats;
                setSeatsPrice((basePrice) + (extraSeats * extraPerPerson));
            }
        }
    }

    const calculateAddsOnsPrice = () => {
        const eventAddons = selectedCard.eventAddons;
        let totalAddsOnsPrice = 0;
        for(const key in eventAddons){
            const selectedAddsOns = eventAddons[key];
            if(selectedAddsOns){
                selectedAddsOns.forEach(addOn => {
                    totalAddsOnsPrice += addOn.price;
                });
            }
        }
        setAddsOnsPrice(totalAddsOnsPrice);
    }

    const changeDate = (e) => {
        const date = e.target.value;
        setSelectedCard({
            ...selectedCard,
            eventDate: date,
            theatreId: '',
            slotId: "",
            totalSeats: ''
        });
    }


    const handleSave = () => {
        gridRef.current.api.deselectAll();
        const requestObj = {
            name: selectedCard.name,
            createdDate: selectedCard.createdDate,
            eventDate : selectedCard.eventDateToDisplay,
            eventType: selectedCard.eventType,
            noOfGuests: selectedCard.noOfGuests,
            preferredTime: selectedCard.preferredTime,
            mobileNumber: selectedCard.mobileNumber,
            status: selectedCard.status,
            referredBy: selectedCard.referredBy,
            location: selectedCard.location,
            notes: selectedCard.notes,
            bookingReferenceId: selectedCard.bookingReferenceId,
            bviacallReferenceId: selectedCard.bviacallReferenceId
        }
        console.log('requestObj',requestObj);
        setShowSpinner(true);
        axios.post(Constants.UPDATE_BOOK_BY_CALL_DETAILS, requestObj).then(() => {
            alert('Data saved');
            // getEvents({date: eventDate});
            getEvents({date: eventDate, toDate: eventToDate});
            setShowPopup(false);
            setShowSpinner(false);
        }).catch((error) => {
            alert('Error saving data', error);
            setShowSpinner(false);
        });
    };

    const handleCancelEvent = () => {
        gridRef.current.api.deselectAll();
        const payloadObj = {
            bookRefId: selectedCard.bookingRefId
        };
        axios.post(Constants.CANCEL_EVENT, payloadObj).then(() => {
            alert('Event Cacelled');
            // getUpComingEvents({date: eventDate});
            getEvents({date: eventDate, toDate: eventToDate});
            setShowPopup(false);
        }).catch((error) => {
            alert('Error while cancel', error);
        });
    };

    const handleClose = () => {
        gridRef.current.api.deselectAll();
        setShowPopup(false);
    };

    const convertToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return (`${day}-${month}-${year}`);
    }

    const convertToYYYYMMDD = (date) => {
        const [day, month, year] = date?.split('-');
        return (`${year}-${month}-${day}`);
    }

    const editTheatre = async(e) => {
        setSelectedCard({
            ...selectedCard,
            theatreId: e.target.value,
            slotId: "",
            totalSeats: ''
        });
        await getTimeSlots(+e.target.value);
    }

    const getEvents = (payload) => {
        setShowSpinner(true);
        axios.post(Constants.GET_ALL_BOOK_VIA_CALL_DATA, payload).then((response) => {
            // console.log('Data fetched', response.data);
            setData(response.data);
            setShowSpinner(false);
        }).catch((error) => {
            console.error('Error fetching data', error);
            setShowSpinner(false);
        });
    }

    const getUpComingEvents = (payload) => {
        axios.post(Constants.GET_ALL_UPCOMING_EVENTS, payload).then((response) => {
            // console.log('Data fetched', response.data);
            setData(response.data);
        }).catch((error) => {
            console.error('Error fetching data', error);
        });
    }

    useEffect(() => {
        // getUpComingEvents({date: eventDate});
        getEvents({date: eventDate, toDate: eventToDate});
        axios.get(Constants.GET_META_DATA_ADDONS)
        .then(response => {
            setFullMetaData(response?.data);
        })
        .catch(error => console.log(error));
    },[]);

    useEffect(() => {
        calculateSeatsPrice();
        calculateAddsOnsPrice();
    },[selectedCard, timeSlotObj]);

    useEffect(() => {
        const timeSlotObj = availableTimeSlotData.find(timeslot => timeslot.id === selectedCard.slotId || 1);
        setTimeSlotObj(timeSlotObj);
    },[availableTimeSlotData]);

    const onSearch = () => {
        getEvents({date: eventDate, toDate: eventToDate,theatreId: selectedTheatre ? +selectedTheatre : null, phoneNumber});
    }


    const onExport = () => {
        gridRef.current.api.exportDataAsCsv();
    };

    const getTimeSlots = (theatreId) => {
        axios.post(Constants.GET_THEATERS_LIST, {date: eventDate})
        .then(response => {
            const theatresData = response.data.details;
            const timeSlotsData = theatresData.find(theatre => theatre.id === theatreId).timeslots;
            // console.log('TimeSlot Data', timeSlotsData);
            setAvailableTimeSlotData(timeSlotsData);
        })
        .catch(error => console.log(error));
    }


    const onRowSelection = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if(selectedRows.length > 0) {
            setSelectedCard(selectedRows[0]);
            let advancePaid = selectedRows[0]?.totalAmountPaid;
            setAdvancePaidTillNow(advancePaid);
            console.log('advancePaid :',advancePaid)
            setInitialSelectedSlotId(selectedRows[0].slotId || 1);
            await getTimeSlots(selectedRows[0].theatreId || 1);
            setShowPopup(true);
        }
    }

    const editTimeSlot = (e) => {
        const slotId = e.target.value;
        const selectedSlot = availableTimeSlotData?.find(slot => slot.id === +slotId);
        setTimeSlotObj(selectedSlot);
        setSelectedCard({
            ...selectedCard,
            slotId
        });
    }

    const editEventData = (e, isNumber) => {
        setSelectedCard({
            ...selectedCard,
            [e.target.name]: isNumber ? +e.target.value : e.target.value
        });
    }

    const updatePaymentToPay = (e, isNumber) => {
        let amtToPay = e.target.value;
        let remainingAmt = seatsPrice + addsOnsPrice - advancePaidTillNow;
        console.log('amtToPay before ',remainingAmt);
        // if(amtToPay < remainingAmt){
        //     amtToPay = remainingAmt;
        // }
        console.log('amtToPay after ',amtToPay);
        // if(advancePaidTillNow < amtToPay){
        //     amtToPay = advancePaidTillNow;
        // }
        // if(amtToPay == 0 || amtToPay == '0'){
        //     console.log('in if')
        //     amtToPay = seatsPrice + addsOnsPrice - advancePaidTillNow;
        // }else{
        //     amtToPay = seatsPrice + addsOnsPrice - e.target.value;
        // }
        console.log('amtToPay ',amtToPay);
        if(amtToPay > remainingAmt){
            amtToPay = remainingAmt;
        }
        e.target.value = amtToPay;

        // selectedCard.remainingAmtToPay
        setSelectedCard({
            ...selectedCard,
            [e.target.name]: isNumber ? +e.target.value : e.target.value,
            remainingAmtToPay: remainingAmt
        });
    }

    // value={} 

    const changeMultiSelect = (e, key) => {
        const eventAddons = {...selectedCard.eventAddons};
        eventAddons[key] = e;
        setSelectedCard({
            ...selectedCard,
            eventAddons
        });
    };

    return (
        <div className='mx-4 px-4 bg-white'>
            {showSpinner && (
           <div className="d-flex justify justify-content-center align-items-center" style={{position:"fixed",top:"0",left:"0",width:"100%",height:"100%",zIndex:"11000",background:"rgba(0, 0, 0, 0.3)"}}> <Spinner animation="border" role="status" className="d-flex justify-content-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>
          )}
            <Form className='admin-filter-section'>
                <Form.Group controlId="eventDate">
                    <Form.Label>Event From Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                    />
                </Form.Group>
                <div style={{margin:'10px'}}><b><i> -- </i></b></div>

                <Form.Group controlId="eventToDate">
                    <Form.Label>Event To Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={eventToDate}
                        onChange={(e) => setEventToDate(e.target.value)}
                    />
                </Form.Group>
                {/* <div style={{margin:'10px'}}><b><i>(or)</i></b></div> */}
                <a onClick={() => onSearch()} className='btn btn-primary bi bi-search' style={{marginLeft:'10px', marginTop:'3%'}}></a>
                <a onClick={() => onExport()} className='btn btn-primary bi bi-download' style={{marginLeft:'auto', marginTop:'3%'}}></a>
            </Form>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={data}
                    suppressExcelExport={true}
                    columnDefs={tableMeta}
                    rowSelection={"single"}
                    onSelectionChanged={onRowSelection}
                />
            </div>

            {/* Popup */}
            {showPopup ?
            <Modal show={true} onHide={handleClose}  centered scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* <EditEventDetails selectedData={sCData}/> */}
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={selectedCard.name} name="name"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="createdDate">
                            <Form.Label>Requested Date</Form.Label>
                            <Form.Control type="text" value={selectedCard.createdDate} name="createdDate" disabled='true' onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="eventDateToDisplay">
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control type="text" value={selectedCard.eventDateToDisplay} name="eventDateToDisplay"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>


                        
                        <Form.Group controlId="eventType">
                            <Form.Label>Event Type</Form.Label>
                            {/* <Form.Control type="text" value={selectedCard.eventType} name="eventType"  onChange={(e) => editEventData(e)}/> */}
                            <Form.Control as="select" value={selectedCard.eventType} name='eventType' className="form-select" onChange={(e) => editEventData(e, false)}>
                            <option value="Birthday">Birthday</option>
                                    <option value="Anniversary">Anniversary</option>
                                    <option value="Bride To be">Bride To be</option>
                                    <option value="Groom To be">Groom To be</option>
                                    <option value="Mom To be">Mom To be</option>
                                    <option value="Farewell">Farewell</option>
                                    <option value="Romantic Date">Romantic Date</option>
                                    <option value="Marriage Proposal">Marriage Proposal</option>
                                    <option value="Love Proposal">Love Proposal</option>
                                    <option value="Baby Shower">Baby Shower</option>
                                    <option value="Congratulations">Congratulations</option>
                                    <option value="Get Together">Get Together</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Others">Others</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="noOfGuests">
                            <Form.Label>Guests</Form.Label>
                            <Form.Control type="text" value={selectedCard.noOfGuests} name="noOfGuests"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="preferredTime">
                            <Form.Label>Preferred Time</Form.Label>
                            {/* <Form.Control type="text" value={selectedCard.preferredTime} name="preferredTime"  onChange={(e) => editEventData(e)}/> */}
                            <Form.Control as="select" value={selectedCard.preferredTime} name='preferredTime' className="form-select" onChange={(e) => editEventData(e, false)}>
                                    <option value="9:00 AM to 12:00 PM">Morning (9:00 AM to 12:00 PM)</option>
                                    <option value="12:30 PM to 3:30 PM">Afternoon (12:30 PM to 3:30 PM)</option>
                                    <option value="4:00 PM to 7:00 PM">Evening (4:00 PM to 7:00 PM)</option>
                                    <option value="7:30 PM to 10:30 PM">Night (7:30 PM to 10:30 PM)</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="mobileNumber">
                            <Form.Label>MOBILE_NUMBER</Form.Label>
                            <Form.Control type="text" value={selectedCard.mobileNumber} name="mobileNumber"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId='status'>
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" value={selectedCard.status} name='status' className="form-select" onChange={(e) => editEventData(e, false)}>
                                <option value="REQUEST_RECEIVED">REQUEST_RECEIVED</option>
                                <option value="CONVERTED_TO_EVENT">CONVERTED_TO_EVENT</option>
                                <option value="REQUEST_ON_HOLD">REQUEST_ON_HOLD</option>
                                <option value="NO_RESPONSE">NO_RESPONSE</option>
                                <option value="PRICE_ISSUE">PRICE_ISSUE</option>
                                <option value="NEED_TIME">NEED_TIME</option>
                                <option value="FOLLOW_UP">FOLLOW_UP</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="bookingRefId">
                            <Form.Label>Booking Reference Id</Form.Label>
                            <Form.Control type="text" value={selectedCard.bookingRefId} name="bookingRefId"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>

                        <Form.Group controlId="referredBy">
                            <Form.Label>Referrence</Form.Label>
                            <Form.Control type="text" value={selectedCard.referredBy} name="referredBy"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="location">
                            <Form.Label>Customer Location</Form.Label>
                            <Form.Control type="text" value={selectedCard.location} name="location"  onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId='notes'>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} value={selectedCard.notes}  name="notes" onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        
                       
                        
                        
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSave()}>
                        Update Details
                    </Button>
                    {/* <Button variant="secondary" onClick={() => handleCancelEvent()}>
                        Cancel Event
                    </Button> */}
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
                 
            </Modal> : ''}
        </div>
    );
};

export default AdminDashboard;
