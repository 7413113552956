import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import BookNow from './pages/BookNow';
import RefundPolicy from './pages/RefundPolicy';
import Admin from './pages/Admin';
import AdminDashboard from './pages/AdminDashboard';
import BookingDashboard from './pages/BookingDashboard';
import ChangeMetadata from './pages/ChangeMetadata';
import EventDetails from './pages/EventDetails';

import TnC from './pages/TnC';
import Privacy from './pages/PrivacyPolicy';
import Addons from './pages/AddOns';
import PageNotFound from "./pages/PageNotFound";

const routes = [
  { path: '/', component: <Home />, exact: true },
  { path: '/Home', component: <Home />, exact: true },
  { path: '/about', component: <About /> },
  { path: '/gallery', component: <Gallery /> },
  { path: '/refundpolicy', component: <RefundPolicy /> },
  { path: '/contact', component: <Contact /> },
  { path: '/booknow', component: <BookNow /> },
  { path: '/admin', component: <Admin /> },
  { path: '/privacy-policy', component: <Privacy /> },
  { path: '/admin/dashboard', component: <AdminDashboard />},
  { path: '/admin/changedata', component: <ChangeMetadata />},
  { path: '/admin/bookingDashboard', component: <BookingDashboard />},
  { path: '/tnc', component: <TnC />},
  { path: '/addons', component: <Addons />},
  {path:'/event-details', component: <EventDetails />},
  {path:'/error', component: <PageNotFound />}
];

export default routes;