import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Context } from '../App';
import * as Constants  from '../api/constants';


const EventDetails = () => {
    const [eventData, setEventData] = useState(null);
    const {context, setContext} = useContext(Context);

    const detailsMap = [['Name', 'customerName'], 
    ['Booking Ref Id', 'bookingRefId'], 
    ['Phone number', 'phoneNumber'], 
    ['Event Date', 'eventDate'], 
    ['Theatre name', 'theatreName'],
    ['Selected Slot', 'slotName'], 
    ['Event Type', 'eventType'], 
    ['Status', 'status'], 
    ['Payment Status', 'paymentStatus'], 
    ['Payment Id', 'paymentResponse.paymentId'],
    ['Selected seats', 'totalSeats'], 
    ['Extra seats', 'extraSeats'], 
    ['Decoration details', 'selectedDecoration'], 
    ['Cake details', 'selectedCake'], 
    ['Flowers details', 'selectedFlowers'], 
    ['Gifts details', 'selectedGiftsDetails'], 
    ['Photos details', 'selectedPhotoDetails'], 
    ['Customer Notes', 'userDirections'], 
    ['Total Invoice amount', 'finalAmountAfterDiscount'], 
    ['Advance paid', 'totalAmountPaid'], 
    ['Final amount to be paid', 'remainingAmtToPay']];

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await axios.post(Constants.GET_EVENT_BY_BOOK_REF_ID, {
                    bookingRefId: context.bookingReferenceId
                });
                setEventData(response.data);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };

        fetchEventDetails();
    }, []);

    const renderEventDetails = () => {
        
        return  detailsMap.map(([label, key]) => (
            // <div style={{display: 'flex', paddingTop: '2%'}}>
           
                <tr key={key}>
                    <td className='fw-bold'>{label}</td>
                    <td>{eventData[key] ? eventData[key]: "-"}</td>
                </tr>
            
            // <div style={{flex: 1, textAlign: 'right', padding: '0 2% 0 0'}} key={key}>
            //     {label}:
            // </div>
            // <div style={{flex: 1}}>
            // <strong>{eventData[key]}</strong>
            // </div>
            // </div>
        ));
    }

    return (
        <div>
            {eventData && (
                <div className='details-card d-flex align-items-center justify-content-center flex-column bg-body'>
                    <div className='text-center details-card-heading'>
                    <h2 className='py-3 text-success fw-bold'>Your Booking Event Details</h2>
                    </div>
                    <table className='table w-50   border border-success table-striped table-hover'>
                        <tbody>
                        {renderEventDetails()}
                        </tbody>

                    </table>

                    {/* <p>
                        <strong>Name:</strong> {eventData.customerName}
                    </p>
                    <p>
                        <strong>Phone number:</strong> {eventData.phoneNumber}
                    </p>
                    <p>
                        <strong>Event Date:</strong> {eventData.eventDate}
                    </p>
                    <p>
                        <strong>Theatre name:</strong> {eventData.theatreId}
                    </p>
                    <p>
                        <strong>Selected Slot:</strong> {eventData.slotId}
                    </p>
                    <p>
                        <strong>Event Type:</strong> {eventData.eventType}
                    </p>
                    <p>
                        <strong>Status:</strong> {eventData.status}
                    </p>
                    <p>
                        <strong>Selected seats:</strong> {eventData.totalSeats}
                    </p>
                    <p>
                        <strong>Extra seats:</strong> {eventData.extraSeats}
                    </p>
                    <p>
                        <strong>Decoration details:</strong> {eventData.addonDecorationId}
                    </p>
                    <p>
                        <strong>Cake details:</strong> {eventData.addonCakesId}
                    </p>
                    <p>
                        <strong>Flowers details:</strong> {eventData.addonFlowersId}
                    </p>
                    <p>
                        <strong>Gifts details:</strong> {eventData.addonGiftsId}
                    </p>
                    <p>
                        <strong>Photos details:</strong> {eventData.addonPhotosId}
                    </p>
                    <p>
                        <strong>Customer Notes:</strong> {eventData.userDirections}
                    </p>
                    <p>
                        <strong>Total Invoice amount:</strong> {eventData.totalAmount}
                    </p>
                    <p>
                        <strong>Advance paid:</strong> {eventData.totalAmountPaid}
                    </p>
                    <p>
                        <strong>Final amount to be paid:</strong> {eventData.finalAmountPaid}
                    </p> */}
                </div>
            )}
        </div>
    );
};

export default EventDetails;