import React, { createContext, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./NavBar";
import routes from "./routes";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./ScrollToTop";

export const Context = createContext();
const App = () => {
  const [context, setContext] = useState({
    eventDate: new Date().toISOString().split("T")[0],
    eventId: "",
    selectedSeats: 0,
    isAdminLoggedIn: false,
    bookingReferenceId: "",
  });
  const [selectedTheaterByDate, setselectedTheaterByDate] = useState({});
  const [eventDate, setEventDate] = useState({});
  const [theatersList, setTheatersList] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [addonMetaData, setAddonMetaData] = useState({});
  const [selectedTime, SetSelectedTime] = useState([]);
  const providerValue = React.useMemo(
    () => ({
      context,
      setContext,
      selectedTheaterByDate,
      setselectedTheaterByDate,
      eventDate,
      setEventDate,
      theatersList,
      setTheatersList,
      totalCost,
      setTotalCost,
      addonMetaData,
      setAddonMetaData,
      selectedTime,
      SetSelectedTime,
    }),
    [
      context,
      selectedTheaterByDate,
      eventDate,
      theatersList,
      addonMetaData,
      selectedTime,
      totalCost,
    ]
  );
  return (
    <Context.Provider value={providerValue}>
      <Router>
        <ScrollToTop>
          <NavBar />
          <div>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Routes>
          </div>
        </ScrollToTop>
        <Footer></Footer>
      </Router>
    </Context.Provider>
  );
};

export default App;
