import { useContext } from "react";
import { Card, Button, CardGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Context } from "../App";

function TheatersList({ TheatersList, getTheaterDetails }) {
  const navigate = useNavigate();
 const { context } = useContext(Context);
  const handleClick = () => navigate('/gallery');

  return (
    <div className="theaterContainer d-md-flex mb-2">
       <CardGroup>
      {TheatersList?.map((list,index) => {
        const idx = index;
        return (
         
          <Card
            className="mb-3"
          >
            {/* <Card.Img variant="top" src={list?.imgLocation} /> */}
            <Card.Img variant="top" src={require(`../images/gallery_images/${list.name.toUpperCase()}.JPG`)} width={"300px"}  onClick={handleClick} />
            <p className="position-absolute bg-color-1-opa70 rounded-start text-white px-3 py-1 viewGallery" style={{fontSize:"11px",top:"15em",right:"0px"}}><a onClick={handleClick} href="javascript:void(0);">View Gallery</a></p>
            <Card.Body onClick={() => getTheaterDetails(list)}>
              <Card.Title className="fw-bold text-center">
                <span className="theater_name logo-color-1">
                  {list.name.toUpperCase()}
                </span>
                &nbsp;
                <span>THEATRE</span>
              </Card.Title>
              <Card.Text>
                <div className={list.availableSlots > 2 ? "text-success mb-3 fs-6" : "text-danger mb-3 fs-6"}>
                  {`${list.availableSlots} Slots available on ${context?.eventDate}`}{" "}
                </div>
                <div className="theater_price align-items-center">
                  <div>
                    Base Fare <span className="fw-bold fs-8"><i class="bi bi-currency-rupee"></i> {list.basePrice} /-</span> for {list.defaultCapacity} or less people
                    {/* Persons: {list.ex}-{list.maxGuests} */}
                  </div>
                </div>
                <div className="theater_price align-items-center">
                  <div>
                    {/* TODO need to change this hard coded value */}
                    Extra price per person :<span className="fw-bold fs-8"><i class="bi bi-currency-rupee"></i> 300 /-</span>
                    {/* <span className="fw-bold fs-8"><i class="bi bi-currency-rupee"></i> {list.basePrice} /-</span> */}
                  </div>
                </div>
              </Card.Text>
              <Button
                variant="btn btn-danger text-white fw-bold fs-5 text-center" disabled={list.availableSlots > 0 ? '' :'disabled'}
                onClick={() => getTheaterDetails(list)}> 
                Book Now
              </Button>
            </Card.Body>
          </Card>
         
        );
      })} </CardGroup>
      
      
    </div>
    
  );
}

export default TheatersList;