import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  Container,
  Card,
  Spinner,
  Row,
  Form,
  Col,
} from "react-bootstrap";
import { Context } from "../App";
import * as Constants from "../api/constants";
import axios from "axios";
import Toaster from "./Toaster";
// Function to load script and append in DOM tree.
const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('error in loading razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});
console.log("AL : ",window.localStorage.getItem("isAdminLoginSuccess"));
const isAdmin =  (window.localStorage.getItem("isAdminLoginSuccess") == "true" ) ? "true" : "false";
function ReviewAddons(props) {
  const { selectedTheaterByDate, selectedTime } = useContext(Context);
  const { totalCost } = useContext(Context);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showInstructions, setshowInstructions] = useState(false);
  const [change, setChange] = useState(false);

  const [toasterDetails, setToasterDetails] = useState(null);
  const { setContext } = useContext(Context);
  const navigate = useNavigate();
  const [validated, set_Validated] = useState(false);
  const [form_Data, set_Form_Data] = useState({
    user: "",
    email: "",
    phoneNo: "",
    admin: "",
    finalPriceAfterDiscount:0
  });
  const submitFn = (event) => {
    const form = document.getElementById("user_form");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setshowInstructions(true);
      // saveChange();
    }
    set_Validated(true);
  };
  const chngFn = (event) => {
    const { name, value } = event.target;
    set_Form_Data({
      ...form_Data,
      [name]: value,
    });
  };
  const renderSelectedProps = (addons) => {
    switch (addons.categoryType) {
      case "CAKES":
        return (
          <li>
            <img src={require('../images/birthday-cake.svg').default} width={"30px"}></img>{`${addons.itemName} cake`} - <span className="text-danger"><i className="bi bi-currency-rupee"></i>{addons.price}</span>
              
          </li>
        );
      case "DECORATION":
        return (
          <li>
           <img src={require('../images/ballon.svg').default} width={"30px"}></img>
{addons.itemName} - <span className="text-danger"><i className="bi bi-currency-rupee"></i>{addons.price}</span>
             
          </li>
        );
      case "PARTY_PROPS":
        return (
          <li>
            <img src={require('../images/props.svg').default} width={"30px"}></img> {addons.itemName} {addons.categoryType} - <span className="text-danger"><i className="bi bi-currency-rupee"></i>{addons.price}</span>
              
          </li>
        );
      case "PHOTOS":
        return (
          <li>
            <img src={require('../images/camera.svg').default} width={"30px"}></img>Photography {addons.itemName} - <span className="text-danger"><i className="bi bi-currency-rupee"></i>{addons.price}</span>
              
          </li>
        );
      default:
        return (
          <li>
            <img src={require('../images/flowers-img.svg').default} width={"30px"}></img> 
              
                {addons.itemName} {addons.categoryType} - <span className="text-danger"><i className="bi bi-currency-rupee"></i>{addons.price}</span>
            
          </li>
        );
    }
  };

  const proceedToRazorPay =  (csReponse) => {
    let payDetails = csReponse.data.payDetails;
   
    // All information is loaded in options which we will discuss later.
    var options = {
      "key": payDetails.keyId, // Enter the Key ID generated from the Dashboard
      "amount": payDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": payDetails.currency,
      "name": payDetails.businessName, //your business name
      "description": payDetails.description,
      "image": payDetails.logoUrl,
      "order_id": payDetails.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // "callback_url": "https://celebrationssquare.com/#/event-details",
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          "name": payDetails.custName, //your customer's name
          "email": payDetails.custEmail,
          "contact": payDetails.custPhone //Provide the customer's phone number for better conversion rates 
      },
      "handler": function (response){
        console.log('Handling successful payment');
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_order_id);
        console.log(response.razorpay_signature);
        updatePaymentDetails(response,csReponse.data);
        //TODO update the event details in DB
      },
      "modal":{
        escape : false,
        "ondismiss" : function(){
          showEventDetails("Payment window closed");
        }
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      }
  };
    var rzp1 = new window.Razorpay(options);
    let source = '';

    // If you want to retreive the chosen payment method.
    rzp1.on('payment.submit', (response) => {
      // paymentMethod.current = response.method;
      console.log('msg=Payment method : ',response.method);
      source = response.method;
    });
    rzp1.on('payment.failed', function (response){
      console.log('Handling fail');
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      // updatePaymentDetails(response,response.data);
      showEventDetails("Payment failed");
    });
    // rzp1.on('payment.success', (response) => {
    //   // paymentId.current = response.error.metadata.payment_id;
    //   window.console.log('Payment success');
    //   console.log("msg=Payment success :",response);
    // });

    // to open razorpay checkout modal.
    rzp1.open();

  }

  const showEventDetails = (status) => {

    setToasterDetails({
      variant: "Success",
      message: status,
    });
    setTimeout(() => {
      navigate("/event-details");
    }, 2000);

  }
  const handleChange = () => {
    setChange(!change);
  }

  const updatePaymentDetails = (rpResponse,csDetails) => {
    setShowSpinner(true);
    console.log('msg=Update Payment details : ',rpResponse);
    // alert('status code :'+rpResponse.status_code)
    let paystatus;
    let failReason = '';
    let orderId = '';
    let paymentId = '';
    let signature = '';
    if(rpResponse.razorpay_payment_id == '' || rpResponse.razorpay_payment_id == null || rpResponse.razorpay_payment_id == undefined){
      failReason = rpResponse.error.reason;
      orderId = rpResponse.error.metadata.order_id;
      paymentId = rpResponse.error.metadata.payment_id;
      paystatus = 'FAILED';
    }else{
      orderId = rpResponse.razorpay_order_id;
      paymentId = rpResponse.razorpay_payment_id;
      signature = rpResponse.razorpay_signature;
      paystatus = 'SUCCESS';
    }
    // alert('orderId : '+orderId)
    // alert('paymentId : '+paymentId)
    // alert('signature : '+signature)
    const reqObj = {
      id: csDetails.eventId,
      paymentResponse : {
        status: paystatus,
        orderId : orderId,
        paymentId : paymentId,
        signature : signature,
        failedReason : failReason,
        amountReceived : csDetails.payDetails.amount
      }
    }
    // alert('msg=Update Pd ; req obj ',reqObj);

    axios
        .post(Constants.UPDATE_PD, { ...reqObj })
        .then((response) => {
          let { bookingReference, status } = response?.data;
          console.log('msg=Response after payment: ',response);
          console.log('msg=Response after bookingReference: ',bookingReference);
          setContext({ bookingReferenceId: bookingReference });
          setToasterDetails({
            variant: "Success",
            message: `${status} and Reference Number is ${bookingReference}`,
          });
          setTimeout(() => {
            navigate("/event-details");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setShowSpinner(false);
          setToasterDetails({
            variant: "Danger",
            message:
              error?.response?.data?.message ||
              "Unable to Book the Event please try again",
          })
        });
  }
  const saveAndPay = () => {
    let { eventDate, eventId, personsCount, slotId } = props.selectedValues;
      const reqObj = {
        eventDate: eventDate,
        customerName: form_Data.user,
        phoneNumber: form_Data.phoneNo,
        email: form_Data.email,
        slotId: Number(slotId),
        theatreId: selectedTheaterByDate?.id,
        eventTypeId: Number(eventId),
        selectedSeats: Number(personsCount),
        extraSeats:
          Number(selectedTime?.defaultCapacity) - Number(personsCount) > 0
            ? Number(selectedTime?.defaultCapacity) - Number(personsCount)
            : 0,
        defaultSeats: Number(selectedTime?.defaultCapacity),
        addons: Object.values(props.selectedAddons),
        theatreName: selectedTheaterByDate?.name,
        nameOnCake: props.nameOnCake,
        paymentType: 'ONLINE'
      };
      axios
        .post(Constants.BOOK_NOW_AND_PAY, { ...reqObj })
        .then((response) => {
          let { bookingReference, status } = response?.data;
          setShowSpinner(false);
          console.log('msg=Response : ',response);
          let orderId = response.data.payDetails.orderId;
          if(orderId != null && orderId != ""){
            try{
              proceedToRazorPay(response);
            }catch(e){
              showEventDetails("Payment Not successful");
            }
            
          }else{
            showEventDetails("Payment Not successful");
          }
          
        })
        .catch((error) => {
          console.log(error);
          setShowSpinner(false);
          setToasterDetails({
            variant: "Danger",
            message:
              error?.response?.data?.message ||
              "Unable to Book the Event please try again",
          });
        });

  }

  const saveNowAndPayLater = () => {
    let { eventDate, eventId, personsCount, slotId } = props.selectedValues;
      const reqObj = {
        eventDate: eventDate,
        customerName: form_Data.user,
        executiveName: (form_Data.admin == '' || form_Data.admin == null) ? "user" : form_Data.admin,
        finalAmountAfterDiscount: form_Data.finalPriceAfterDiscount,
        phoneNumber: form_Data.phoneNo,
        email: form_Data.email,
        slotId: Number(slotId),
        theatreId: selectedTheaterByDate?.id,
        eventTypeId: Number(eventId),
        selectedSeats: Number(personsCount),
        extraSeats:
          Number(selectedTime?.defaultCapacity) - Number(personsCount) > 0
            ? Number(selectedTime?.defaultCapacity) - Number(personsCount)
            : 0,
        defaultSeats: Number(selectedTime?.defaultCapacity),
        addons: Object.values(props.selectedAddons),
        theatreName: selectedTheaterByDate?.name,
        nameOnCake: props.nameOnCake,
        paymentType: 'OFFLINE'
      };
      axios
        .post(Constants.BOOK_NOW_AND_PAY_LATER, { ...reqObj })
        .then((response) => {
          let { bookingReference, status } = response?.data;
          setShowSpinner(false);
          // console.log('msg=Response : ',response);
         // proceedToRazorPay(response.data.payDetails);
          setContext({ bookingReferenceId: bookingReference });
          setToasterDetails({
            variant: "Success",
            message: `${status} and Reference Number is ${bookingReference}`,
          });
          setTimeout(() => {
            navigate("/event-details");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setShowSpinner(false);
          setToasterDetails({
            variant: "Danger",
            message:
              error?.response?.data?.message ||
              "Unable to Book the Event please try again",
          });
        });

  }

  const savePayLater = async () => {
    setShowSpinner(true);
    setshowInstructions(false);
    saveNowAndPayLater();
    
  };
  const savePayNow = async () => {
    setShowSpinner(true);
    setshowInstructions(false);
    saveAndPay();
    
  };
  return (
    <>
    <div>
      <Modal show={props?.isShow} animation={false} size="xl">
        {toasterDetails && <Toaster details={toasterDetails} />}
        <Modal.Body>
          {showSpinner && (
           <div className="d-flex justify justify-content-center align-items-center" style={{position:"fixed",top:"0",left:"0",width:"100%",height:"100%",zIndex:"11000",background:"rgba(0, 0, 0, 0.3)"}}> <Spinner animation="border" role="status" className="d-flex justify-content-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>
          )}
          <div className="row ">
            <div className="col-md-6 col-12">
              <h3 className="theater_name text-center fw-b">
                <span className="logo-color-1 ">
                  {selectedTheaterByDate?.name.toUpperCase()}
                </span>
                &nbsp;
                <span>Theatre</span>
              </h3>
              <hr className="m-0 border-danger bg-danger" size='6' style={{"opacity":1}}></hr>
              <div className="m-3 mt-0 p-3 border border-danger light-orange-color-bg" id="reviewDetails">
                <ul className="list-unstyled">
                  <li className="text-danger fw-bold fs-6">Total: <span><i className="bi bi-currency-rupee"></i> {props.totalCost}</span></li>
                  
          <li> <i className="bi bi-calendar"></i> <span> {props.selectedValues.eventDate}</span></li>
          <li> <i className="bi bi-person-circle"></i> 
          <span> {props.selectedValues.personsCount} Persons</span> - 
          <span className="text-danger"><i className="bi bi-currency-rupee">{totalCost}</i>{props.selectedTheaterByDate}</span></li>
                {Object.entries(props?.selectedAddons).map(([key, value]) => {
                  console.log(value);
                  return renderSelectedProps(value);
                })}
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <Container>
                <Card  className="border-3 border-orange">
                  <h3
                    
                    className="fs-5 py-2 fw-bold bg-color-1-opa text-white text-center"
                  >
                    Enter Your Details to Proceed further
                  </h3>
                  <Card.Body>
                    <Card.Text className="col-md-12">
                      <Container>
                        <Row>
                          <Col>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={submitFn}
                              id="user_form" autoComplete="off"
                            >
                              <Form.Group controlId="username" className="mb-3">
                                {/* <Form.Label>Username</Form.Label> */}
                                <Form.Control
                                  type="text"
                                  name="user"
                                  value={form_Data.user}
                                  onChange={chngFn}
                                  pattern="^[a-zA-Z\s]+$"
                                  required
                                  placeholder="Name(Max 15 characters)*"
                                  maxLength={15}
                                  isInvalid={
                                    validated &&
                                    !/^[a-zA-Z\s]+$/.test(form_Data.user)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid username (alphanumeric
                                  characters only).
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group controlId="phoneNumber" className="mb-3">
                                {/* <Form.Label>Phone Number</Form.Label> */}
                                <Form.Control
                                  type="number"
                                  name="phoneNo"
                                  value={form_Data.phoneNo}
                                  onChange={chngFn}
                                  pattern="^\d{10}$"
                                  placeholder="whatsApp number (10 digits of indianNumber)*"
                                  required
                                  isInvalid={
                                    validated &&
                                    !/^\d{10}$/.test(form_Data.phoneNo)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid 10-digit phone number.
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group controlId="email" className="mb-3">
                                {/* <Form.Label>Email</Form.Label> */}
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={form_Data.email}
                                  onChange={chngFn}
                                  placeholder="name@example.com"
                                  required
                                  isInvalid={
                                    validated &&
                                    !/^\S+@\S+\.\S+$/.test(form_Data.email)
                                  }
                                />
                                {/* {isAdmin} */}
                              <Form.Group controlId="admin" className="mb-3">
                                <Form.Control
                                    type="text"
                                    style={{display:isAdmin == "true" ? 'inline' : 'none'}}
                                    name="admin"
                                    value={form_Data.admin}
                                    onChange={chngFn}
                                    pattern="^[a-zA-Z\s]+$"
                                    placeholder="Admin Name"
                                    maxLength={15}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter a valid username (alphanumeric
                                    characters only).
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="finalPrice" className="mb-3">
                                {/* <Form.Label>Phone Number</Form.Label> */}
                                <Form.Control
                                  type="number"
                                  name="finalPriceAfterDiscount"
                                  value={form_Data.finalPriceAfterDiscount}
                                  style={{display:isAdmin == "true" ? 'inline' : 'none'}}
                                  onChange={chngFn}
                                  pattern="^\d{5}$"
                                  placeholder="Final amount after discount"
                                />
                                <Form.Control.Feedback>
                                  Please enter a valid 10-digit phone number.
                                </Form.Control.Feedback>
                              </Form.Group>


                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid email address.
                                </Form.Control.Feedback>

                                
                              </Form.Group>

                              <p className="p-2 m-2 light-orange-color-bg text-muted" style={{borderRadius:"10px",
                              display:isAdmin == "true" ? 'inline' : 'none',fontSize:"14Px",fontWeight:"bold",backgroundColor:"red"}}>
                  Please cross check final price after discount.
                </p>
                              <p className="p-2 m-2 light-orange-color-bg text-muted" style={{borderRadius:"10px"}}>
                  We collect an advance amount of ₹ 500 + convenience fee (₹50)
                  to book the slot. Remaining bill will be collected at the time
                  of check-out.
                </p>

                
                              {/* <Button type="submit">Submit</Button> */}
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Text>
                    <Card.Body></Card.Body>
                  </Card.Body>
                </Card>
                
              </Container>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="btn btn-dark bg-transparent text-dark fw-bold  fs-6 text-center"
            onClick={() => props?.closeReview()}
          >
            Close
          </Button>
          <Button
            variant="btn text-white  fs-6 text-center"
            onClick={submitFn}
          >
            Proceed to Pay
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInstructions}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <h3 className="text-center mb-3">Important Instructions</h3>
          <p className="px-3 px-md-5 bg-danger text-white rounded-4 text-sm mt-2">Refund Policy : Partial advance amount (Rs 500/-) will be refundable if you cancel the slot atleast 72 hours prior to your booking time.</p>
          <ul style={{listStyleType:'numher'}}>
            <li>
            Smoking and Consumption of Alcohol is strictly prohibited inside the Theatres.
            </li>
            <li>You need to bring your own OTT accounts to watch the content.</li>
            <li>Party poppers, foam and Champaigne is not allowed inside the theatres, considering the sensitivity of the Theatres.</li>
            <li>Outside food is strictly prohibited, considering the sensitivity of carpets and recliners inside the Theatres.</li>
            <li>We charge full for children above or equal to 5 years and half for those who are below 5 years</li>
            <li>Right to admission is reserved by the Management.</li>
            <li>If Professional photography choosen, timing of the photography is according to the availability of the photographer. Only soft copies will be provided.</li>
            <li><b>We collect an advance amount of ₹ 500 to book the slot, Our team will reach out to you regarding this.</b></li>
            </ul>
            <div className="justify-content-center d-md-flex">
            <Form.Check 
            type="checkbox" id="confirmInstructions" label="I agree to all the above conditions." onChange={handleChange}
          /></div>

        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button
            variant="btn btn-dark text-white  fs-6 text-center"
            onClick={() => setshowInstructions(false)}
          >
            Close
          </Button>
          <Button
            variant="btn btn-danger text-white  fs-6 text-center"
            // onClick={submitFn}
            onClick={() => savePayLater()} disabled={!change}
          >
            Save Now Pay Later
          </Button>
{ 
/**Comment the below button to remove payment gateway option */
          /*
          <Button
            variant="btn btn-danger text-white  fs-6 text-center"
            // onClick={submitFn}
            onClick={() => savePayNow()} disabled={!change} id="rzp-button1"
          >
            Confirm and Pay Advance
          </Button>
          */
           }
        </Modal.Footer>
      </Modal>
      </div>
    </>
  );
}

export default ReviewAddons;
