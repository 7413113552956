
import styles from "../css/RefundPolicy.css";
const TnC = () =>
    <section className="inner-pages" id="innerpages">
        <div className="container">
            <div className="row">
                <div className="col col-md12">

                    <div class="accordion accordion-flush" id="tncAccordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc1">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc1" aria-expanded="false" aria-controls="tnc1">
                                    Booking Confirmation
                                </button>
                            </h2>
                            <div id="tnc1" class="accordion-collapse collapse " aria-labelledby="htnc1" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    All bookings are subject to availability and must be confirmed in advance.
                                    A non-refundable deposit may be required to secure the booking.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc2">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc2" aria-expanded="false" aria-controls="tnc2">
                                    Payment

                                </button>
                            </h2>
                            <div id="tnc2" class="accordion-collapse collapse " aria-labelledby="htnc2" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Full payment is required before the commencement of the event.
                                    Payment methods accepted include cash, credit/debit cards, or bank transfer.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc3">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc3" aria-expanded="false" aria-controls="tnc3">
                                    Cancellation Policy

                                </button>
                            </h2>
                            <div id="tnc3" class="accordion-collapse collapse " aria-labelledby="htnc3" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Cancellations made within [X] days of the event may result in forfeiture of the deposit.
                                    Celebrations Square reserves the right to cancel or reschedule bookings due to unforeseen circumstances. In such cases, a full refund or alternative booking date will be offered.

                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc4">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc4" aria-expanded="false" aria-controls="tnc4">
                                    Event Duration

                                </button>
                            </h2>
                            <div id="tnc4" class="accordion-collapse collapse " aria-labelledby="htnc4" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    The duration of the event includes setup and cleanup time.
                                    Any additional time required beyond the agreed duration may be subject to additional charges.

                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc5">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc5" aria-expanded="false" aria-controls="tnc5">
                                    Guest Count

                                </button>
                            </h2>
                            <div id="tnc5" class="accordion-collapse collapse " aria-labelledby="htnc5" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    The maximum capacity of the private theater room must not be exceeded.
                                    Any changes to the guest count must be communicated to Celebrations Square at least [X] days before the event.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc6">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc6" aria-expanded="false" aria-controls="tnc6">
                                    Conduct

                                </button>
                            </h2>
                            <div id="tnc6" class="accordion-collapse collapse " aria-labelledby="htnc6" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Guests are expected to behave responsibly and respect the property and staff of Celebrations Square.
                                    Any damage caused to the premises or equipment during the event will be the responsibility of the booking party and may incur additional charges.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc7">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc7" aria-expanded="false" aria-controls="tnc7">
                                    Food and Beverage
                                </button>
                            </h2>
                            <div id="tnc7" class="accordion-collapse collapse " aria-labelledby="htnc7" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Outside food and beverages may be permitted with prior approval from Celebrations Square.
                                    Celebrations Square may offer catering services upon request, subject to additional charges.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc8">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc8" aria-expanded="false" aria-controls="tnc8">
                                    Safety and Security
                                </button>
                            </h2>
                            <div id="tnc8" class="accordion-collapse collapse " aria-labelledby="htnc8" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Celebrations Square reserves the right to conduct security checks as deemed necessary.
                                    Guests are responsible for their belongings, and Celebrations Square will not be liable for any loss or theft.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc9">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc9" aria-expanded="false" aria-controls="tnc9">
                                    Liability

                                </button>
                            </h2>
                            <div id="tnc9" class="accordion-collapse collapse " aria-labelledby="htnc9" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Celebrations Square will not be liable for any injury, illness, or loss of property sustained during the event, except in cases of negligence.

                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="htnc10">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tnc10" aria-expanded="false" aria-controls="tnc10">
                                    Compliance

                                </button>
                            </h2>
                            <div id="tnc10" class="accordion-collapse collapse " aria-labelledby="htnc10" data-bs-parent="#tncAccordion">
                                <div class="accordion-body">
                                    Guests must comply with all local laws and regulations, including those related to noise levels, smoking, and alcohol consumption.


                                </div>
                            </div>
                        </div>
                        <p className="mt-3">By confirming a booking with Celebrations Square, the booking party agrees to abide by these Terms and Conditions. Celebrations Square reserves the right to amend these terms at any time without prior notice.
                        </p>
                    </div>

                </div>
            </div>

        </div>

    </section>
export default TnC;
