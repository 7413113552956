import React from 'react';
const theater_images = [1,2,3,4,5,6,7,8,9]; const gallery_images = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
const Gallery = () =>

    <section className="inner-pages" id="innerpages">
        <div className="container">
            <div className="row ">
                <div className="col col-md12">
                    <h1 className='text-center logo-color-1'>Immerse Yourself: Explore Our Private Theatre Gallery</h1>
                    <div className='d-flex my-10 flex align-items-center justify-content-center' id="cs-gallery">
                        <ul className='list-unstyled mw-100' style={{columnCount:"3",columnGap:"20px"}}>
                        {theater_images.map(theatre => (<li className='mt-0 mb-md-4 mb-3'>
                <img 

                  src={require('../images/Theatre_images/'+theatre+'.jpg')}
                  alt="" loading="lazy" style={{ width: "100%", height:"100%", objectFit:"cover" }}
                />
              </li>))}
              {gallery_images.map(gallery => (<li className='mt-0 mb-md-4 mb-3'>
                <img 

                  src={require('../images/gallery_images/'+gallery+'.JPG')}
                  alt="" loading="lazy" style={{ width: "100%", height:"100%", objectFit:"cover" }}
                />
              </li>))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>;

export default Gallery;