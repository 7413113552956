import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();
    // Automatically scrolls to top whenever route changes
    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'instant' }); //  Instant is for without scroll animation 
    }, [location]);

    return <>
        {props.children}
    </>
  };

export default ScrollToTop;