import React, { useEffect, useState, useContext } from 'react';
import { Nav, Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import * as Constants  from '../api/constants';
import AdminMetaDataForm from '../components/AdminMetaDataForm';
import jsonMetaData from '../json/addon_form.json';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Context } from '../App';
import { useNavigate  } from 'react-router-dom';

const ChangeMetadata = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState(null);
    const [selectedItem, setSelectedItem] = useState('theatres');
    const [isNewForm, setIsNewForm] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Others');
    const [selectedItemData, setSelectedItemData] = useState({});
    const [selectedTheatreForSlots, setSelectedTheatreForSlots] = useState('Select Theatre');
    const [selectedTheatreId, setSelectedTheatreId] = useState('');
    const {context, setContext} = useContext(Context);
    const navigate = useNavigate();

    if(!context.isAdminLoggedIn){
        navigate('/admin');
    }

    const handleAddNew = () => {
        setSelectedItemData({});
        setIsNewForm(true);
        setShowPopup(true);
    };

    const editItem = (data) => {
        setSelectedItemData(data);
        setIsNewForm(false);
        setShowPopup(true);
    };

    const addnewAddOn = () => {
        setSelectedItem('Add Addon');
        setSelectedItemData({});
        setIsNewForm(true);
        setShowPopup(true);
    };

    const togglePopup = (isOpen) => {
        setShowPopup(isOpen);
    }

    useEffect(() => {
        axios.get(Constants.GET_META_DATA_ADDONS).then((response) => {
            setData(response.data);
        }).catch((error) => {
            console.error('Error fetching data', error);
        });
    },[]);

    const menuItemClicked = (key) => {
        setSelectedItem(key);
    }

    const renderMenuItems = (isAddOn) => {
        const exculsions = ['addonsData', 'statusList']
        const dataToLoop = isAddOn ? data?.addonsData : data;
        return dataToLoop && Object.keys(dataToLoop).map((key) => {
            if(exculsions.includes(key)){
                return;
            }
            let className = '';
            if(key === selectedItem){
                className = 'admin-menu-selected';
            }
            return (<Nav.Link key={key} id={`menu_${key}`} className={className} onClick={() => menuItemClicked(key)}>{key}</Nav.Link>)
        });
    }

    const renderItemOptions = (isAddOn) => {
        const itemNametoFetch = isAddOn ? jsonMetaData.addonsData?.listName : jsonMetaData[selectedItem]?.listName;
        const dataToFetch = isAddOn ? data?.addonsData?.[selectedItem] : data?.[selectedItem];
        return dataToFetch?.map((dataObj) => {
            return (
            <div className="admin-meta-list" key={dataObj.id} onClick={() => editItem(dataObj)}>{dataObj?.[itemNametoFetch]}</div>
            )
        });
    }

    const onTabChange = (key) => {
        if(key === 'addOn'){
            setSelectedItem('CAKES');
            setSelectedTab('Addons')
        }else{
            setSelectedTab('Others');
            setSelectedItem('theatres');
        }
    }

    const changeTheatre = (theatreObj) => {
        setSelectedTheatreForSlots(theatreObj.name);
        setSelectedTheatreId(theatreObj.id)
    }

    const renderTheatreOptions = () => {
        return data?.theatres?.map((dataObj) => {
            return (<Dropdown.Item onClick={() => changeTheatre(dataObj)}>{dataObj.name}</Dropdown.Item>)
        });
    }

    const renderTheatresList = () => {
        return data?.timeslots?.filter((timeslot) => timeslot.id === selectedTheatreId).map((dataObj) => {
            return (
            <div className="admin-meta-list" key={dataObj.id} onClick={() => editItem(dataObj)}>{dataObj?.name}</div>
            )
        });
    }
            

    return (
        <div className='mx-4 px-4 py-3 bg-white'>


        <Tabs
            defaultActiveKey="other"
            id="justify-tab-example"
            className="mb-3"
            justify
            onSelect={(key) => onTabChange(key)}
        >
            <Tab eventKey="other" title="Others">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Nav className="flex-column metalinks" style={{flex: 1}}>
                        {renderMenuItems(false)}
                    </Nav>
                    <div style={{flex: 3}}>
                        {selectedItem === 'timeslots' ? 
                        <div>
                        <div style={{display: 'flex'}}>
                            <DropdownButton title={selectedTheatreForSlots} style={{flex: 4}}>
                                {renderTheatreOptions()}
                            </DropdownButton>
                            <Button variant="secondary" onClick={handleAddNew} style={{ flex: 1}}>Add New</Button>
                        </div> 
                        <div style={{padding:'10px'}}>
                            <h4>Available {selectedItem} Lists</h4>
                            { data && renderTheatresList()}
                        </div>
                        </div>:
                    <div><Button variant="primary" onClick={handleAddNew} style={{ float: 'right'}}>Add New</Button>
                        <div style={{padding:'10px'}}>
                            <h4>Available {selectedItem} Lists</h4>
                            { data && renderItemOptions(false)}
                        </div></div>}
                    </div>
                </div>
            </Tab>
            <Tab eventKey="addOn" title="Add ons">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Nav className="flex-column metalinks" style={{flex: 1}}>
                        {renderMenuItems(true)}
                        <Nav.Link key={'add_new'} onClick={() => addnewAddOn()}><i>Add New Add-on</i></Nav.Link>
                    </Nav>
                    <div style={{flex: 3}}>
                        {selectedItem !== 'Add Addon' ?
                        <div>
                        <Button variant="primary" onClick={handleAddNew} style={{ float: 'right'}}>Add New</Button>
                        <div style={{padding:'10px'}}>
                            <h4>Available {selectedItem} Lists</h4>
                            { data && renderItemOptions(true)}
                        </div></div> : null}
                    </div>
                </div>
            </Tab>
        </Tabs>
        <Modal show={showPopup} onHide={() => togglePopup(false)} centered scrollable>
            <AdminMetaDataForm type={selectedItem} selectedTab={selectedTab} data={selectedItemData} togglePopup={togglePopup}/>
        </Modal>
        </div>
    );
};

export default ChangeMetadata;
