import React, { useEffect, useState, useContext, useRef } from 'react';
import { Card, Form, Button, Modal,Spinner } from 'react-bootstrap';
import axios from 'axios';
import * as Constants  from '../api/constants';
import EditEventDetails from '../components/EditEventDetails';
import { Context } from '../App';
import { useNavigate  } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CsvExportModule } from "ag-grid-community";
import { ModuleRegistry } from "ag-grid-community";
import Select from 'react-select';

ModuleRegistry.registerModules([
  CsvExportModule,
]);

const AdminDashboard = () => {
    const gridRef = useRef();

    const tableMeta = [
        {headerName: 'S No', field: 'sNo', width: 75},
        {headerName: 'Name', field: 'customerName', width: 150},
        {headerName: 'Event', field: 'eventType', width: 120},
        {headerName: 'Contact', field: 'phoneNumber', width: 120},
        {headerName: 'Date', field: 'eventDate', width: 120},
        {headerName: 'Theatre', field: 'theatreName', width: 150},
        {headerName: 'Slot', field: 'slotName', width: 180},
        {headerName: 'Seats', field: 'totalSeats', width: 80},
        {headerName: 'Total Invoice', field: 'totalAmount', width: 150},
        {headerName: 'Final Amount After discount', field: 'finalAmountAfterDiscount', width: 150},
        {headerName: 'Amount Paid', field: 'totalAmountPaid', width: 150},
        {headerName: 'Remaining Amount to be Paid', field: 'remainingAmtToPay', width: 180},
        {headerName: 'F&B Charges', field: 'foodOtherCharges', width: 150},
        {headerName: 'Payment Mode', field: 'paymentMode', width: 150},
        {headerName: 'Booking Status', field: 'status', width: 120},
        {headerName: 'Payment Status', field: 'paymentStatus', width: 120},
        {headerName: 'Booking Id', field: 'bookingRefId', width: 150},
        {headerName: 'Decoration', field: 'selectedDecoration', width: 150},
        {headerName: 'Cake', field: 'selectedCake', width: 150},
        {headerName: 'Name on Cake', field: 'userDirections', width: 150},
        {headerName: 'Flowers', field: 'selectedFlowers', width: 150},
        {headerName: 'Party Props', field: 'selectedPartyProps', width: 150},
        {headerName: 'Gifts', field: 'selectedGiftsDetails', width: 150},
        {headerName: 'Photo', field: 'selectedPhotoDetails', width: 150},
        {headerName: 'Admin Notes', field: 'adminNotes', width: 150},
        
        
    ];
    const nextDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()+30);

    const [eventDate, setEventDate] = useState(new Date().toISOString().split('T')[0]);
    const [eventToDate, setEventToDate] = useState(nextDate.toISOString().split('T')[0]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [finalPaymentToPay, setFinalPaymentToPay] = useState(0);
    const [selectedCard, setSelectedCard] = useState({});
    const [data, setData] = useState([]);
    const {context, setContext} = useContext(Context);
    const [selectedTheatre, setSelectedTheatre] = useState('');
    const navigate = useNavigate();
    const [availableTimeSlotData, setAvailableTimeSlotData] = useState([]);
    const [fullMetaData, setFullMetaData] = useState(null);
    const [timeSlotObj, setTimeSlotObj] = useState({});
    const [initialSelectedSlotId, setInitialSelectedSlotId] = useState(null);
    const [seatsPrice, setSeatsPrice] = useState(0);
    const [foodOtherPrice, setFoodOtherPrice] = useState(0);
    const [advancePaidTillNow, setAdvancePaidTillNow] = useState(0);
    const [finalPriceAfterDiscount, setFinalPriceAfterDiscount] = useState(0);
    
    const [addsOnsPrice, setAddsOnsPrice] = useState(0);

    if(!context.isAdminLoggedIn){
        navigate('/admin');
    }

    const calculateSeatsPrice = () => {
        if(timeSlotObj){
            const selectedSeats = selectedCard.totalSeats;
            const defaultSeats = timeSlotObj.defaultCapacity;
            const extraPerPerson = timeSlotObj.extraPerPerson;
            const basePrice = timeSlotObj.basePrice;
            const advTillnow = selectedCard.totalAmountPaid;
            // setAdvancePaidTillNow(advTillnow);
            if(selectedSeats <= defaultSeats){
                setSeatsPrice(basePrice);
            }else{
                const extraSeats = selectedSeats - defaultSeats;
                setSeatsPrice((basePrice) + (extraSeats * extraPerPerson));
            }
        }
    }

    const calculateAddsOnsPrice = () => {
        const eventAddons = selectedCard.eventAddons;
        let totalAddsOnsPrice = 0;
        for(const key in eventAddons){
            const selectedAddsOns = eventAddons[key];
            if(selectedAddsOns){
                selectedAddsOns.forEach(addOn => {
                    totalAddsOnsPrice += addOn.price;
                });
            }
        }
        setAddsOnsPrice(totalAddsOnsPrice);
    }

    const changeDate = (e) => {
        const date = e.target.value;
        setSelectedCard({
            ...selectedCard,
            eventDate: date,
            theatreId: '',
            slotId: "",
            totalSeats: ''
        });
    }


    const handleSave = () => {
        gridRef.current.api.deselectAll();
        const payloadObj_old = {
            eventDate: selectedCard.eventDate,
            customerName: selectedCard.customerName,
            phoneNumber: selectedCard.phoneNumber,
            slotId: selectedCard.slotId,
            theatreId: selectedCard.theatreId,
            eventTypeId: selectedCard.eventType,
            totalSeats: selectedCard.totalSeats,
            nameOnCake: selectedCard.userDirections,
            addons: selectedCard.eventAddons,
            adminNotes: selectedCard.adminNotes
        };
        let finalAmountToPay = seatsPrice + addsOnsPrice - selectedCard.totalAmountPaid;
        if(null == selectedCard.paymentMode || selectedCard.paymentMode == '' || selectedCard.paymentMode == 'OFFLINE'){
            alert("Payment mode should be - Bank or Cash");
            return;
        }
        const payloadObj = {
            paymentMode: selectedCard.paymentMode,
            amountPaid: selectedCard.totalAmountPaid,
            remainingFinalAmount : finalAmountToPay,
            bookingRefId: selectedCard.bookingRefId,
            foodOtherCharges: selectedCard.foodOtherCharges,
            adminNotes: selectedCard.adminNotes,
            finalAmountAfterDiscount: selectedCard.finalAmountAfterDiscount
        }
        console.log('payloadObj',payloadObj);
        setShowSpinner(true);
        axios.post(Constants.UPDATE_EVENT_DETAILS, payloadObj).then(() => {
            alert('Data saved');
            // getEvents({date: eventDate});
            getEvents({date: eventDate, toDate: eventToDate});
            setShowPopup(false);
            setShowSpinner(false);
        }).catch((error) => {
            alert('Error saving data', error);
            setShowSpinner(false);
        });
    };

    const handleCancelEvent = () => {
        gridRef.current.api.deselectAll();
        const payloadObj = {
            bookRefId: selectedCard.bookingRefId
        };
        axios.post(Constants.CANCEL_EVENT, payloadObj).then(() => {
            alert('Event Cacelled');
            // getUpComingEvents({date: eventDate});
            getEvents({date: eventDate, toDate: eventToDate});
            setShowPopup(false);
        }).catch((error) => {
            alert('Error while cancel', error);
        });
    };

    const handleClose = () => {
        gridRef.current.api.deselectAll();
        setShowPopup(false);
    };

    const convertToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return (`${day}-${month}-${year}`);
    }

    const convertToYYYYMMDD = (date) => {
        const [day, month, year] = date?.split('-');
        return (`${year}-${month}-${day}`);
    }

    const editTheatre = async(e) => {
        setSelectedCard({
            ...selectedCard,
            theatreId: e.target.value,
            slotId: "",
            totalSeats: ''
        });
        await getTimeSlots(+e.target.value);
    }

    const getEvents = (payload) => {
        setShowSpinner(true);
        axios.post(Constants.GET_ALL_EVENTS, payload).then((response) => {
            // console.log('Data fetched', response.data);
            setData(response.data);
            setShowSpinner(false);
        }).catch((error) => {
            console.error('Error fetching data', error);
            setShowSpinner(false);
        });
    }

    const getUpComingEvents = (payload) => {
        axios.post(Constants.GET_ALL_UPCOMING_EVENTS, payload).then((response) => {
            // console.log('Data fetched', response.data);
            setData(response.data);
        }).catch((error) => {
            console.error('Error fetching data', error);
        });
    }

    useEffect(() => {
        // getUpComingEvents({date: eventDate});
        getEvents({date: eventDate, toDate: eventToDate});
        axios.get(Constants.GET_META_DATA_ADDONS)
        .then(response => {
            setFullMetaData(response?.data);
        })
        .catch(error => console.log(error));
    },[]);

    useEffect(() => {
        calculateSeatsPrice();
        calculateAddsOnsPrice();
    },[selectedCard, timeSlotObj]);

    useEffect(() => {
        const timeSlotObj = availableTimeSlotData.find(timeslot => timeslot.id === selectedCard.slotId || 1);
        setTimeSlotObj(timeSlotObj);
    },[availableTimeSlotData]);

    const onSearch = () => {
        getEvents({date: eventDate, toDate: eventToDate,theatreId: selectedTheatre ? +selectedTheatre : null, phoneNumber});
    }


    const onExport = () => {
        gridRef.current.api.exportDataAsCsv();
    };

    const getTimeSlots = (theatreId) => {
        axios.post(Constants.GET_THEATERS_LIST, {date: eventDate})
        .then(response => {
            const theatresData = response.data.details;
            const timeSlotsData = theatresData.find(theatre => theatre.id === theatreId).timeslots;
            // console.log('TimeSlot Data', timeSlotsData);
            setAvailableTimeSlotData(timeSlotsData);
        })
        .catch(error => console.log(error));
    }


    const onRowSelection = async () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if(selectedRows.length > 0) {
            setSelectedCard(selectedRows[0]);
            let advancePaid = selectedRows[0]?.totalAmountPaid;
            setAdvancePaidTillNow(advancePaid);
            let finalAfterDiscount = selectedRows[0]?.finalAmountAfterDiscount;
            setFinalPriceAfterDiscount(finalAfterDiscount);
            console.log('advancePaid :',advancePaid)
            setInitialSelectedSlotId(selectedRows[0].slotId || 1);
            await getTimeSlots(selectedRows[0].theatreId || 1);
            setShowPopup(true);
        }
    }

    const editTimeSlot = (e) => {
        const slotId = e.target.value;
        const selectedSlot = availableTimeSlotData?.find(slot => slot.id === +slotId);
        setTimeSlotObj(selectedSlot);
        setSelectedCard({
            ...selectedCard,
            slotId
        });
    }

    const editEventData = (e, isNumber) => {
        setSelectedCard({
            ...selectedCard,
            [e.target.name]: isNumber ? +e.target.value : e.target.value
        });
    }

    const updatePaymentToPay = (e, isNumber) => {
        let amtToPay = e.target.value;
        // let remainingAmt = seatsPrice + addsOnsPrice - advancePaidTillNow;
        let remainingAmt = finalPriceAfterDiscount - advancePaidTillNow;
        console.log('amtToPay before ',remainingAmt);
        // if(amtToPay < remainingAmt){
        //     amtToPay = remainingAmt;
        // }
        console.log('amtToPay after ',amtToPay);
        // if(advancePaidTillNow < amtToPay){
        //     amtToPay = advancePaidTillNow;
        // }
        // if(amtToPay == 0 || amtToPay == '0'){
        //     console.log('in if')
        //     amtToPay = seatsPrice + addsOnsPrice - advancePaidTillNow;
        // }else{
        //     amtToPay = seatsPrice + addsOnsPrice - e.target.value;
        // }
        console.log('amtToPay ',amtToPay);
        if(amtToPay > remainingAmt){
            amtToPay = remainingAmt;
        }
        // e.target.value = amtToPay;

        // selectedCard.remainingAmtToPay
        setSelectedCard({
            ...selectedCard,
            [e.target.name]: isNumber ? +e.target.value : e.target.value,
            remainingAmtToPay: remainingAmt
        });
    }

    // value={} 

    const changeMultiSelect = (e, key) => {
        const eventAddons = {...selectedCard.eventAddons};
        eventAddons[key] = e;
        setSelectedCard({
            ...selectedCard,
            eventAddons
        });
    };

    return (
        <div className='mx-4 px-4 bg-white'>
            {showSpinner && (
           <div className="d-flex justify justify-content-center align-items-center" style={{position:"fixed",top:"0",left:"0",width:"100%",height:"100%",zIndex:"11000",background:"rgba(0, 0, 0, 0.3)"}}> <Spinner animation="border" role="status" className="d-flex justify-content-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>
          )}
            <Form className='admin-filter-section'>
                <Form.Group controlId="eventDate">
                    <Form.Label>Event From Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="eventToDate">
                    <Form.Label>Event To Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={eventToDate}
                        onChange={(e) => setEventToDate(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="TheatreName" style={{marginLeft:'10px'}}>
                    <Form.Label>Theatre Name</Form.Label>
                    <Form.Control as="select" value={selectedTheatre} onChange={(e) => setSelectedTheatre(e.target.value)} 
                    className="form-select">
                            <option value="">--Please choose an option--</option>
                            {
                                fullMetaData?.theatres.map((theatre, index) => {
                                    return <option key={index} value={theatre.id}>{theatre.name}</option>
                                })
                            }
                    </Form.Control>
                </Form.Group>
                <div style={{margin:'10px'}}><b><i>(or)</i></b></div>
                <Form.Group controlId="phoneNumber" style={{marginLeft:'10px'}}>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </Form.Group>
                <a onClick={() => onSearch()} className='btn btn-primary bi bi-search' style={{marginLeft:'10px', marginTop:'3%'}}></a>
                <a onClick={() => onExport()} className='btn btn-primary bi bi-download' style={{marginLeft:'auto', marginTop:'3%'}}></a>
                <a onClick={() => navigate('/admin/bookingDashboard')} className='btn btn-secondary' style={{ marginLeft: 'auto' }}>Bookings Dashboard</a>
                <a onClick={() => navigate('/admin/changedata')} className='btn btn-secondary' style={{ marginLeft: 'auto' }}>Change MetaData</a>
            </Form>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={data}
                    suppressExcelExport={true}
                    columnDefs={tableMeta}
                    rowSelection={"single"}
                    onSelectionChanged={onRowSelection}
                />
            </div>

            {/* Popup */}
            {showPopup ?
            <Modal show={true} onHide={handleClose}  centered scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* <EditEventDetails selectedData={sCData}/> */}
                        <Form.Group controlId="customerName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={selectedCard.customerName} name="customerName" disabled='true' onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="tel" value={selectedCard.phoneNumber} name="phoneNumber" disabled='true' onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="bookiingRefId">
                            <Form.Label>Booking Ref Id</Form.Label>
                            <Form.Control type="text" value={selectedCard.bookingRefId} name="bookingRefId" disabled='true' onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId='eventDate'>
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control type="date" value={selectedCard.eventDate} name='eventDate' disabled='true' onChange={(e) => changeDate(e)}/>
                        </Form.Group>
                        <Form.Group controlId='theatreName'>
                            <Form.Label>Theatre Name</Form.Label>
                            <Form.Control as="select" value={selectedCard.theatreId} name='theatreId' disabled='true' className="form-select" onChange={(e) => editTheatre(e)}>
                                <option value="">--Please choose an option--</option>
                                {
                                    fullMetaData?.theatres.map((theatre, index) => {
                                        return <option key={index} value={theatre.id}>{theatre.name}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='slotId'>
                            <Form.Label>Selected Slot</Form.Label>
                            <Form.Control as="select" value={selectedCard.slotId} name='slotId' disabled='true' className="form-select" onChange={(e) => editTimeSlot(e)}>
                                <option value="">--Please choose an option--</option>
                                {
                                    availableTimeSlotData?.map((slot, index) => {
                                        return <option key={index} value={slot.id} disabled={initialSelectedSlotId !== slot.id && !slot.isAvailable}>{slot.slotTime}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='eventType'>
                            <Form.Label>Event Type</Form.Label>
                            <Form.Control as="select" value={selectedCard.eventType} name='eventType' disabled='true' className="form-select" onChange={(e) => editEventData(e, true)}>
                                <option value="">--Please choose an option--</option>
                                {
                                    fullMetaData?.eventTypes.map((event, index) => {
                                        return <option key={index} value={event.id}>{event.name}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='status'>
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" value={selectedCard.status} name='status' disabled='true' className="form-select" onChange={(e) => editEventData(e)}>
                                <option value="">--Please choose an option--</option>
                                {
                                    fullMetaData?.statusList.map((status, index) => {
                                        return <option key={index} value={status.name}>{status.description}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='selectedSeats'>
                            <Form.Label>Selected Seats</Form.Label>
                            <Form.Control as="select" value={selectedCard.totalSeats} name='totalSeats' disabled='true' className="form-select" onChange={(e) => editEventData(e, true)}>
                                <option value="">--Please choose an option--</option>
                                {
                                    Array(timeSlotObj?.maxGuests).fill().map((_, index) => {
                                        return <option key={index} value={index+1}>{index+1}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='decoration'>
                            <Form.Label>Decoration Details</Form.Label>
                            <Select
                                isMulti={true}
                                disabled={true} 
                                value={selectedCard.eventAddons?.DECORATION}
                                onChange={(e) => changeMultiSelect(e, 'DECORATION')}
                                getOptionLabel={option => option.itemName}
                                getOptionValue={option => option.id}
                                options={fullMetaData?.addonsData?.DECORATION}
                            />
                        </Form.Group>
                        <Form.Group controlId='cake'>
                            <Form.Label>Cake Details</Form.Label>
                            <Select
                                isMulti={true}
                                value={selectedCard.eventAddons?.CAKES}
                                onChange={(e) => changeMultiSelect(e, 'CAKES')}
                                getOptionLabel={option => option.itemName}
                                getOptionValue={option => option.id}
                                options={fullMetaData?.addonsData?.CAKES}
                            />
                        </Form.Group>
                        <Form.Group controlId='flower'>
                            <Form.Label>Flower Details</Form.Label>
                            <Select
                                isMulti={true}
                                value={selectedCard.eventAddons?.FLOWERS}
                                onChange={(e) => changeMultiSelect(e, 'FLOWERS')}
                                getOptionLabel={option => option.itemName}
                                getOptionValue={option => option.id}
                                options={fullMetaData?.addonsData?.FLOWERS}
                            />
                        </Form.Group>
                        <Form.Group controlId='photos'>
                        <Form.Label>Photos Details</Form.Label>
                            <Select
                                isMulti={true}
                                value={selectedCard.eventAddons?.PHOTOS}
                                onChange={(e) => changeMultiSelect(e, 'PHOTOS')}
                                getOptionLabel={option => option.itemName}
                                getOptionValue={option => option.id}
                                options={fullMetaData?.addonsData?.PHOTOS}
                            />
                        </Form.Group>
                        <Form.Group controlId='partyProps'>
                            <Form.Label>Party Props</Form.Label>
                            <Select
                                isMulti={true}
                                value={selectedCard.eventAddons?.['PARTY PROPS']}
                                onChange={(e) => changeMultiSelect(e, 'PARTY PROPS')}
                                getOptionLabel={option => option.itemName}
                                getOptionValue={option => option.id}
                                options={fullMetaData?.addonsData?.['PARTY PROPS']}
                            />
                        </Form.Group>
                        <Form.Group controlId='notes'>
                            <Form.Label>Customer Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} value={selectedCard.userDirections} disabled='true' name="userDirections" onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                        <Form.Group controlId="totalAmount">
                            <Form.Label>Total Invoice Amount</Form.Label>
                            <Form.Control type="text" value={seatsPrice + addsOnsPrice} disabled/>
                        </Form.Group>
                        {/* <Form.Group controlId="finalAmount">
                            <Form.Label>Final amount after discount</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group> */}
                        <Form.Group controlId="price">
                            <div style={{display: 'flex'}}>
                            <div style={{flex: '2'}} ><Form.Label>Seats Price :</Form.Label> <span><b> {seatsPrice} </b></span></div>
                            <div style={{flex: '2'}} ><Form.Label>Addons Price :</Form.Label> <span><b> {addsOnsPrice} </b></span></div>
                            {/* <div style={{flex: '2'}} ><Form.Label>F&B Charges :</Form.Label> <span><b> {selectedCard.foodOtherCharges} </b></span></div> */}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="advance">
                            <Form.Label>Final amount after discount</Form.Label>
                            <Form.Control type="number" value={selectedCard.finalAmountAfterDiscount} name="finalAmountAfterDiscount" 
                                onChange={(e) => editEventData(e, true)}/>
                        </Form.Group>
                        <Form.Group controlId="paidTillNow">
                            <Form.Label>Advance paid till now</Form.Label>
                            <Form.Control type="text" value={advancePaidTillNow} disabled/>
                        </Form.Group>
                        <Form.Group controlId="advance">
                            <Form.Label>Amount Receiving now</Form.Label>
                            <Form.Control type="number" name="totalAmountPaid" 
                                onChange={(e) => updatePaymentToPay(e, true)}/>
                        </Form.Group>
                        <Form.Group controlId="foodAndOtherCharges">
                            <Form.Label>F&B Charges</Form.Label>
                            <Form.Control type="text" value={selectedCard.foodOtherCharges} name="foodOtherCharges" 
                                onChange={(e) => editEventData(e, true)}/>
                        </Form.Group>
                        <Form.Group controlId="finalPaymentToPay">
                            <Form.Label>Remaining Amount to be Paid</Form.Label>
                            <Form.Control type="text" disabled='true' value={selectedCard.remainingAmtToPay} name="finalAmountPaid"/>
                        </Form.Group>
                        <Form.Group controlId='paymentMode'>
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control as="select" value={selectedCard.paymentMode} name='paymentMode' className="form-select" 
                                    onChange={(e) => editEventData(e, false)}>
                                <option value="">--Please choose an option--</option>
                                <option value="Bank">Bank</option>
                                <option value="Cash">Cash</option>
                                <option value="OFFLINE">OFFLINE</option>
                                
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="cancellation">
                            <Form.Label>Cancellation Charges</Form.Label>
                            <Form.Control type="text" disabled='true' value={selectedCard.cancellationCharges} name="cancellationCharges" onChange={(e) => editEventData(e, true)}/>
                        </Form.Group>
                        <Form.Group controlId='adminNotes'>
                            <Form.Label>Admin Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} maxLength="250" value={selectedCard.adminNotes} name="adminNotes" onChange={(e) => editEventData(e)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSave()}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={() => handleCancelEvent()}>
                        Cancel Event
                    </Button>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> : ''}
        </div>
    );
};

export default AdminDashboard;
