import { useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Context } from '../App';
import SelectedEvent from '../pages/SelectedEvent';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const TheateDetails = () => {
  const { selectedTheaterByDate, selectedTime } = useContext(Context);
  var settings = {
    lazyLoad: 'ondemand',
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: 'linear',
    fade: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          arrows: false,
          dots: false,
        }
      }
    ]
  };
  const theater_images = 
  {
    "PLATINUM": ["4.jpg","5.jpg","6.jpg","7.jpg"],
    "GOLD": ["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg"]
  };
  const selectedTheater = selectedTheaterByDate?.name.toUpperCase();
  return (
    // <section style={{ background: `url(${require("../images/main-party-style-pg.jpg")})`, backgroundRepeat: 'repeat', backgroundPosition: "25% bottom" }}>
    <section className='bookNow'>
      <div className='d-md-flex justify-content-center py-4 container d-sm-flex flex-sm-column flex-md-row '>
        <div className='bg-white rounded-start p-md-5 p-2'>
          <h1 className='theater_name'><span className='logo-color-1'>{selectedTheaterByDate?.name.toUpperCase()}</span>&nbsp;<span>THEATRE</span></h1>

          <p className='fs-5'><i class="bi bi-currency-rupee"></i>{`${selectedTime?.basePrice}/- for ${selectedTime?.minGuests} or less people (`}<i class="bi bi-currency-rupee"></i>{`${selectedTime?.extraPerPerson} for extra person)`}</p>
          <div className=' p-md-3 p-2 theaterDetails'>
            <Slider {...settings} style={{  margin: "0 auto",backgroundPosition:"center",backgroundSize:"contain" }}>
             {theater_images[selectedTheaterByDate?.name.toUpperCase()].map(theatre => (<div>
                <img className='rounded-3'

                  src={require(`../images/Theatre_images/${selectedTheaterByDate?.name.toUpperCase()}/${theatre}`)}
                  alt={"theatre " +theatre} style={{width:"100%", height: "400px" }}
                />
              </div>))}
            </Slider>
          </div>
        </div>
        <div className='rounded-end'>
          <SelectedEvent />
        </div>
      </div>
    </section>
  );
}

export default TheateDetails;