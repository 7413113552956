import React, { useState, useContext } from 'react';
import { useNavigate  } from 'react-router-dom';
import axios from 'axios';
import * as constants  from '../api/constants';
import { Context } from '../App';

const Admin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {context, setContext} = useContext(Context);

  const handleLogin = (e) => {
    e.preventDefault();
    const payloadObj = {
      name: username,
      password
    };
    axios.post(constants.ADMIN_LOGIN_VALIDATE, payloadObj).then((response) => {
      if(response.data.statuscode === 200){
        navigate('/admin/dashboard');
        setContext({isAdminLoggedIn: true});
        window.localStorage.setItem("isAdminLoginSuccess", "true");

      }else{
        alert(response.data.message);
      }

    }).catch(() => {
        alert('Username or Password is incorrect. Please try again.');
    });
   
    
  };

  return (
    <div className="container text-center bg-white" style={{height: '250px', marginTop: '10px'}}>
      <h3>Login into Admin</h3>
      <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '300px', margin: '0 auto' }}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Admin;
