import { useContext, useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import DatePicker from "../components/DatePicker";
import { Context } from "../App";
import AddonsList from "../components/AddonsList";
import ScrollToTop from "../ScrollToTop";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

function SelectedEvent() {
  const {
    selectedTheaterByDate,
    context,
    setTotalCost,
    SetSelectedTime,
    selectedTime,
    theatersList,
    addonMetaData,
  } = useContext(Context);
  const [selectedTheater, setSelectedTheater] = useState({});
  const [count, setCount] = useState([]);
  const [extraPersonPrice, setExtraPersonPrice] = useState(0);
  const [showAddOns, setShowAddOns] = useState(false);
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    const selectedValue = theatersList?.filter(
      (list) => list.id === selectedTheaterByDate.id
    );
    setSelectedTheater(selectedValue[0]);
    //  setExtraPersonPrice(selectedValue[0]?.basePrice); // Need to remove this
    setTotalCost(selectedValue[0]?.basePrice);
  }, [theatersList, selectedTheaterByDate, setTotalCost]);

  useEffect(() => {
    setExtraPersonPrice(selectedTime?.basePrice);
    setTotalCost(selectedTime?.basePrice);
  }, [selectedTime, setTotalCost]);

  useEffect(() => {
    var intialcount = [];
    for (var i = 1; i < selectedTime?.maxGuests + 1; i++) {
      intialcount.push(i);
    }
    setCount(intialcount);
  }, [selectedTime, setCount]);

  const onSelectPersons = (number) => {
    if (number > selectedTime?.defaultCapacity) {
      const updatePrice =
        (number - selectedTime?.defaultCapacity) *
          selectedTime?.extraPerPerson +
        selectedTime?.basePrice;
      setExtraPersonPrice(updatePrice);
      setTotalCost(updatePrice);
    } else {
      setExtraPersonPrice(selectedTime?.basePrice);
      setTotalCost(selectedTime?.basePrice);
    }
  };
  const onAddonSelect = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    var formDataObj = Object.fromEntries(formData.entries());
    setFormData(formDataObj);
    setShowAddOns(true);
  };
  const resetData = () => {
    setExtraPersonPrice(0); // Need to remove this
    // setTotalCost(0);
    setSelectedTheater([]);
    setCount([]);
  };
  const onSelectSlot = (slotId) => {
    const selectedTime = selectedTheater?.timeslots?.filter(
      (slots) => slots.id === slotId
    )[0];
    SetSelectedTime(selectedTime);
    setCount([1]);
  };
  // const onSelectEvent = (event) => {
  //   // selectedTheater?.eventId = Number(event);
  //   setContext({"eventId": event});
  // };
  return (
    <ScrollToTop>
      <Container className="p-0">
        <Form onSubmit={onAddonSelect}>
          <Card style={{ border: "3px solid #00327B" }}>
            <h3
              style={{
                backgroundColor: "#00327B",
                color: "white",
                textAlign: "center",
              }}
              className="py-2 fw-bold"
            >
              BOOKING
            </h3>
            {/* <Card.Img variant="top" src="hero-bg.jpg" /> */}
            <Card.Body>
              {/* <Card.Title>BOOKING</Card.Title> */}
              <Card.Text className="col-md-12">
                <DatePicker dateStyle={"col-md-12"} onDateChange={resetData} />
                {/* <div className="margin10">
                  <Form.Select
                    aria-label="Select slot"
                    className="cs-select"
                    placeholder="Slots*"
                    name="slotId"
                    onChange={(e) => onSelectSlot(Number(e.target.value))}
                  >
                    {selectedTheater?.timeslots?.map((slots) => {
                      return (
                        <option
                          value={slots?.id}
                          disabled={!slots?.isAvailable}
                        >
                          {slots.slotTime}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div> */}
                <FloatingLabel
          controlId="floatingSelectGrid"
          label="Slots"
        >
          <Form.Select  className="cs-select" aria-label="Select slot" name="slotId"
                    onChange={(e) => onSelectSlot(Number(e.target.value))}>
           {selectedTheater?.timeslots?.map((slots) => {
                      return (
                        <option
                          value={slots?.id}
                          disabled={!slots?.isAvailable} className="my-2"
                        >
                          {slots.slotTime}
                        </option>
                      );
                    })}
          </Form.Select>
        </FloatingLabel>
                <div className="margin10">
                  <Form.Select
                    aria-label="Select event"
                    className="cs-select"
                    placeholder="Event*"
                    name="eventId"
                  >
                    {addonMetaData?.eventTypes?.map((event) => {
                      return (
                        <option
                          value={event?.id}
                          disabled={!event?.isActive === "Y"}
                        >
                          {event.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                {/* <div className="margin10">
                  <Form.Select
                    aria-label="Select number of persons"
                    className="cs-select"
                    placeholder="Number of persons*"
                    name="personsCount"
                    controlId="persons"
                    onChange={(e) => onSelectPersons(Number(e.target.value))}
                  >
                    {count?.map((number) => {
                      return <option value={number}>{number}</option>;
                    })}
                  </Form.Select>
                </div> */}
                <FloatingLabel
          controlId="floatingSelectGrid"
          label="No. of person"
        >
          <Form.Select  className="cs-select" aria-label="Select number of persons" name="personsCount" controlId="persons"
                    onChange={(e) => onSelectPersons(Number(e.target.value))}>
          {count?.map((number) => {
                      return <option value={number}>{number}</option>;
                    })}
          </Form.Select>
        </FloatingLabel>
              </Card.Text>
              <Card.Body>
                <div className="theater_name">
                  <span className="logo-color-1 fs-3 fw-bold">
                    <i class="bi bi-currency-rupee"></i>
                    {`${extraPersonPrice}/-`}
                  </span>
                </div>
                <p className="fs-6">*Inclusive of all taxes</p>
                <p className="theater_green fs-6 text-wrap">
                  {`${selectedTheater?.availableSlots} Slots available on ${context?.eventDate} in selected Theater`}{" "}
                </p>
                
              </Card.Body>
              <button
                type="submit"
                className="btn btn-dark text-white fw-bold fs-4 text-center" disabled={selectedTheater?.availableSlots>0 ? '' :'disabled'}
                // onClick={onAddonSelect}
              >
                Next
              </button>
            </Card.Body>
          </Card>

          {showAddOns && (
            <AddonsList
              formData={formData}
              isShow={showAddOns}
              hideModel={() => setShowAddOns(false)}
            />
          )}
        </Form>
      </Container>
    </ScrollToTop>
  );
}

export default SelectedEvent;
