import { Toast, ToastContainer } from "react-bootstrap";

function Toaster(props) {
  return (
    <>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 1 }}
      ></ToastContainer>
      <Toast
        delay={3000}
        autohide
        className="d-inline-block m-1"
        bg={props?.details?.variant?.toLowerCase()}
      >
        {/* <Toast.Header> */}
          {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
          {/* <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small> */}
        {/* </Toast.Header> */}
        <Toast.Body className={props?.details?.variant === "Dark" && "text-white"}>
          {props?.details?.message}
        </Toast.Body>
      </Toast>
    </>
  );
}

export default Toaster;
