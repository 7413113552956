import styles from "../css/RefundPolicy.css";
const RefundPolicy = () =>
    <section className="inner-pages" id="innerpages">
        <div className="container">
            <div className="row ">
                <div className="col col-md12">
                    <h1>Refund Policy</h1>

                    <ul>

                        <li>An advance amount of Rs 700/- is collected for the confirmation of bookings.</li>
                        <ul>
                            <li>Of this amount, Rs 500/- is considered as a partial advance, and the remaining Rs 200/- is presumably non-refundable.</li>

                            <li>The partial advance amount of Rs 500/- is refundable if you cancel your slot 72 hours prior to your booking.</li>
                        </ul>
                        <li>No refunds are offered for cancellations made after the 72-hour window, no-shows, or late arrivals.</li>

                        <li>If you need to cancel your booking, please contact us as soon as possible through WhatsApp at +918143678222.</li>

                        <li>Upon receiving your cancellation request, we will make arrangements for your refund.</li>

                        <li>Refunds will be processed within 7 business days of receiving your cancellation request.</li>

                        <li>Thank you for emphasizing your willingness to assist with any questions or concerns regarding the refund policy.</li>


                    </ul>
                </div>
            </div>
        </div>

    </section>
export default RefundPolicy;