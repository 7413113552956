import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Context } from "../App";
import ReviewAddons from "./ReviewAddons";

function AddonsList(props) {
  const { totalCost, addonMetaData } = useContext(Context);
  const totalAddons = Object.keys(addonMetaData.addonsData);
  const [addOnCount, setAddOnCount] = useState(0);
  const [currentAddon, setCurrentAddon] = useState(
    addonMetaData.addonsData[totalAddons[addOnCount]]
  );
  const [selectedAddon, setSelectedAddon] = useState([]);
  const [addonCost, setAddOnCost] = useState(0);
  const [isReview, setIsReview] = useState(false);
  const [nameOnCake, setNameOnCake] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCustomize, setIsCustomize] = useState([]);
  const message = "Please Enter Required Details.";
  const DECORATION =
    "Decorations are not customizable. Please select predefined add-ons in the next screen.";
  const CAKES =
    "Above images are for demonstration purposes only. Actual cake may look different.";
  const PHOTOS =
    "Timing of the photography is according to the availability of the photographer. Only soft copies will be provided.";
 const PHOTOPROPS = 'For photoclippings, our team will reach out to you on the day of booking. You need to send 16 soft copies of the photos you want to place inside the theater. You can take those hard copies with you after the party!'
  const nextAddOn = () => {
    if (
      isCustomize?.isCustomize === "Y" &&
      !nameOnCake
    ) {
      setErrorMessage(message);
    } else {
      if (totalAddons.length - 1 > addOnCount) {
        setAddOnCount(addOnCount + 1);
        setCurrentAddon(addonMetaData.addonsData[totalAddons[addOnCount + 1]]);
      } else {
        setIsReview(true);
      }
    }
  };

  const prevAddon = () => {
    setAddOnCount(addOnCount - 1);
    setCurrentAddon(addonMetaData.addonsData[totalAddons[addOnCount - 1]]);
  };
  const onAddonSelect = (addProps, isSelected) => {
    if (isSelected) {
      const filtered = selectedAddon.filter((key) => key.id !== addProps.id);
      setSelectedAddon(filtered);
    } else {
      if (
        addProps?.allowMultiple === "Y" &&
        Object.entries(selectedAddon).length > 0
      ) {
        setSelectedAddon((oldArray) => [...oldArray, addProps]);
      } else {
        setSelectedAddon([addProps]);
      }
    }
  };
  useEffect(() => {
    if (selectedAddon) {
      let price = 0;
      Object.keys(selectedAddon).forEach((val) => {
        price = selectedAddon[val]["price"] + price;
      });
      setAddOnCost(price);
    }
  }, [selectedAddon]);
  const onNameChange = (value) => {
    setNameOnCake(value);
  };
  useEffect(() => {
    nameOnCake ? setErrorMessage("") : setErrorMessage(message);
  }, [nameOnCake]);

  const getCategoryTypeNote = () => {
    // if(categoryType.toLowerCase=='cakes'){
    //   return 'birthday-cake';
    // }else{
    //   return 'props'
    // }
    return document.getElementById("propcategory").value;
  };
  useEffect(() => {
    let isSelected = selectedAddon?.filter(
      (val) => val?.categoryType === totalAddons[addOnCount]
    )[0];
    setIsCustomize(isSelected);
    const element = document?.getElementById("customeMessage");
    element?.scrollIntoView();
  }, [setIsCustomize, totalAddons, addOnCount]);
  return (
    <>
      <Modal
        show={props?.isShow}
        fullscreen={false}
        onHide={() => props?.hideModel(false)}
        size="xl"
        id="csquare-addons"
      >
        <Modal.Header closeButton className="bg-color-1-opa">
          <Modal.Title className="text-white fs-4 fw-b d-flex justify-content-between flex-grow-1">
            <div> {totalAddons[addOnCount]}</div>
            <div>Total: <i class="bi bi-currency-rupee"></i>{totalCost + addonCost} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="justify-content-center row gap-md-4 gap-4 w-100 row-cols-4 mb-5"
          >
            {/* <ul className="c2square-addons-list w-100"> */}
            {/* <Slider {...settings}>   */}
            {/* <div className=""> */}
            {currentAddon?.map((propsList) => {
              let isSelected = false;
              if (selectedAddon.length) {
                isSelected = selectedAddon?.filter(
                  (val) => val?.id === propsList?.id
                )[0];
              }

              return (
                // <Card
                //   className={
                //     isSelected
                //       ? " border-warning"
                //       : null + " border  col-sm-1.5 me-2 mb-2 h-20 card"
                //   }
                //   onClick={() => onAddonSelect(propsList, isSelected)}
                // >
                //   <Card.Img
                //     variant="top"
                //     src={(propsList?.imageName)}
                //     className="mr-auto"
                //   />
                //   <Card.Body>
                //     <Card.Title>{propsList.itemName}</Card.Title>
                //     <Card.Text>{propsList.price}</Card.Text>
                //   </Card.Body>
                // </Card>
                //  <li className="rounded-3"><img src={(propsList?.imageName)}/>{propsList.itemName}</li>
                <div
                  onClick={() => onAddonSelect(propsList, isSelected)}
                  className={
                    isSelected
                      ? "rounded-3 border border-3 addon-list selected-addon border-success d-flex flex-column  align-items-center col-md-3 col-5 border border-2 text-center p-md-5 p-sm-3"
                      : "rounded-3 border border-3 addon-list border-warning d-flex flex-column  align-items-center col-md-3 col-5 border border-2 text-center p-md-5 p-sm-3"
                  }
                >
                  {/* <img src={require('../images/'+renderImage(propsList.categoryType)+'.svg').default} alt="Addon" /> */}
                  {/* <img
                    src={require("../images/" +
                      propsList.categoryType +
                      ".svg")}
                    alt="Addon"
                    className="border border-light border-3 rounded-circle"
                  /> */}
                  <img src={require("../images/" +
                      propsList.imageName)} alt="Addon" />
                  <span className="logo-color-1 fs-6 barlow-semibold lh-1 my-3">
                    {propsList.itemName}
                  </span>
                  <span><i class="bi bi-currency-rupee"></i>{propsList.price}</span>
                </div>
              );
            })}
            {/* </div> */}
            {/* </Slider> */}
            {/* </ul> */}
          </div>
          {isCustomize?.isCustomize === "Y" && (
            <div className="d-flex  justify-content-center">
              <div class="form-group" id="customeMessage">
                <div className="text-danger">{errorMessage}</div>
                <input
                  required
                  type="input"
                  class="mx-auto fw-b fs-8 px-4"
                  id="form-control"
                  placeholder="Enter Name of the Person"
                  maxLength={25}
                  onChange={(e) => onNameChange(e.target.value)}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="flex-column">
          <p className="fs-6 text-danger">
            {totalAddons[addOnCount] == "DECORATION"
              ? "Note: Decorations are not customizable. Please select predefined add-ons in the next screen."
              : ""}
            {totalAddons[addOnCount] == "CAKES"
              ? "Note: Above images are for demonstration purposes only. Actual cake may look different."
              : ""}
            {totalAddons[addOnCount] == "PHOTOS" ? "Note: " + PHOTOS : ""}
            {totalAddons[addOnCount] == "PARTY PROPS" ? "Note: " + PHOTOPROPS : ""}
          </p>
          <div className="justify-content-between d-flex w-100 ">
            {addOnCount > 0 && (
              <Button
                className="btn btn-dark bg-transparent   text-dark fw-bold fs-4 justify-content-start"
                onClick={prevAddon}
              >
                Back
              </Button>
            )}
            <Button
              className="btn btn-dark text-white fw-bold fs-5 text-right justify-content-end"
              onClick={nextAddOn}
            >
              {/* <i class="bi bi-arrow-right"></i> */}
              {totalAddons.length - 1 > addOnCount ? "Next" : "Review"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {isReview && (
        <ReviewAddons
          isShow={isReview}
          selectedAddons={selectedAddon}
          totalCost={totalCost + addonCost}
          selectedValues={props?.formData}
          closeReview={() => setIsReview(false)}
          nameOnCake={nameOnCake}
        />
      )}
    </>
  );
}

export default AddonsList;
