import React from 'react';

const Contact = () => <section className="inner-pages" id="innerpages">
    <div className="container">
        <div className="row">
            <div className="col col-md5 col-12">
                <h2>Contact Us</h2>
                <h3 className='my-3'>Celebrations Square Entertainments</h3>
                <p className='mb-3'>#307, 3rd Floor<br></br>
                    Pancom Business Center <br></br>
                    Ameerpet<br></br>
                    Hyderabad<br></br>
                    500073<br></br>
                    Email: <a href="mailto:info@celebrationssquare.com&amp;subject=Booking%20Private%20Theatre%20for%20Celebrations:%20Availability%20Inquiry%20&amp;%20Reservation%20Details%20Needed&amp;body=I%20would%20like%20to%20book%20a%20private%20theatre%20for%20a%20celebration! Please%20let%20me%20know%20about%20availability%20and%20the%20reservation%20process. ">info@celebrationssquare.com</a>
                    <br></br>Contact: +91 8143678222 (WhatsApp Only)</p>
            </div>
            <div className='col col-md7 col-12'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.554334197021!2d78.44605229999999!3d17.4331626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91000eec6e2f%3A0xc799adb82728c388!2sCelebrations%20Square%20Entertainments!5e0!3m2!1sen!2sin!4v1714290059576!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</section>

export default Contact;