import React from 'react';
import { useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  return (
 <section style={{ backgroundImage: `url(${require("../src/images/footer_bg.jpg")})`, height: '375px', textAlign: 'center', padding: "20px" }}>
 <div>
     <img src="celebrations-square-logo.svg"
         width="125px"
         className="d-inline-block align-top"
         alt="Celebrations Square"
     />

 </div>
 <ul className='list-unstyled my-4'><li className='list-inline-item fs-6'>
  <a href="javascript:void(0);" onClick={() => navigate("/")} className='link-light'>Home</a></li><li className='list-inline-item fs-6'>
  <a href="javascript:void(0);"   onClick={() => navigate("/about")} className='link-light'>About us</a></li>
 <li className='list-inline-item fs-6'><a href="javascript:void(0);"  onClick={() => navigate("/gallery")}  className='link-light'>Gallery</a></li>
 <li className='list-inline-item fs-6'><a href="javascript:void(0);"  onClick={() => navigate("/privacy-policy")} className='link-light'>Privacy policy</a></li>
 <li className='list-inline-item fs-6'><a href="javascript:void(0);" onClick={() => navigate("/tnc")} className='link-light'>Terms n Conditions</a></li>
 <li className='list-inline-item fs-6'><a href="javascript:void(0);" onClick={() => navigate("/contact")} className='link-light'>Contact Us</a></li></ul>
 <ul className='list-unstyled'><li className='list-inline-item'> <a href="https://www.instagram.com/celebrations_square/" target="_blank" rel="noreferrer"><i className="bi bi-instagram link-info"></i>
 </a></li><li className='list-inline-item'><a href="https://www.youtube.com/@Celebrationssquare" target="_blank" rel="noreferrer"><i className="bi bi-youtube link-primary"></i></a></li><li className='list-inline-item'><a href="https://www.facebook.com/people/Celebrationssquare/61558146910554/" target="_blank" rel="noreferrer"><i className="bi bi-facebook link-secondary"></i></a></li></ul>

</section>
  );
};

export default Footer;
