import styles from "../css/RefundPolicy.css";
const About = () =>
<section className="inner-pages" id="innerpages">
    <div className="container">
    <div className="row">
        <div className="col col-md12">
        <h2>About Celebrations Square</h2>
<h3 className="mb-2">Welcome to Celebrations Square, where we turn your celebrations into unforgettable cinematic experiences!</h3>

<p>At Celebrations Square, we specialize in creating immersive private theater celebrations that elevate your special moments to new heights. Whether you're celebrating a birthday, anniversary, graduation, or any other milestone, we believe that every occasion deserves the red carpet treatment.
</p>
<h3>Our Mission</h3>
<p>Our mission is simple: to transform ordinary events into extraordinary memories. We're passionate about bringing people together through the power of entertainment and creating magical moments that last a lifetime.
</p>
<h3>What Sets Us Apart</h3>
<p><b>Exclusivity:</b> We offer private theater experiences tailored to your preferences, ensuring an intimate celebration surrounded by your closest friends and family.
</p>
<p><b>State-of-the-Art Technology:</b> Our theaters are equipped with cutting-edge audiovisual technology, delivering crystal-clear visuals and immersive sound to enhance your viewing experience.
</p>
<p><b>Personalized Service:</b> From customizable playlists to themed decorations, we go above and beyond to tailor every detail to your vision, ensuring a truly personalized celebration.
</p>
<p><b>Expert Team:</b> Our team of experienced professionals is dedicated to making your event seamless and stress-free, handling everything from setup to teardown so you can focus on making memories.
</p>
<h3>Our Story</h3>
<p>Celebrations Square was born out of a passion for bringing people together through the magic of cinema.<br></br>
Driven by a commitment to excellence and a love for creating unforgettable experiences, we continue to innovate and exceed expectations with every event we host.
</p>
<h3>Get in Touch</h3>
<p>Ready to take your celebration to the next level? Contact us today to learn more about our services and start planning your dream event with Celebrations Square.
</p>   </div>
    </div>

    </div>
    
</section>
export default About;